/* React */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

/* Functions and Utils */
import { User } from "firebase/auth";

/* UI */
import { Auth, getWithAuth } from "../firebase/authentication";

import "./requirements.css";

const AGNSY_SERVER_ADDRESS = process.env.REACT_APP_AGNSY_SERVER_ADDRESS;
const HTTP_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";

type Requirement = {
  check: () => Promise<boolean>;
  redirect: string;
};

function withRequirements(
  Component: React.ComponentType,
  requirements: Requirement[]
) {
  function WithRequirements() {
    const [isLoading, setIsLoading] = useState(true);
    const [canRender, setCanRender] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
      const runChecks = async () => {
        for (const { check, redirect } of requirements) {
          const passed = await check();
          if (!passed) {
            navigate(redirect);
            return;
          }
        }
        setCanRender(true);
        setIsLoading(false);
      };

      runChecks();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
      return (
        <div className="loading-spinner">
          <div className="loading-spinner__outer" />
          <div className="loading-spinner-text">Verifying user...</div>
        </div>
      );
    }

    return canRender ? <Component /> : null;
  }

  return WithRequirements;
}

const userSignedInRequirement: Requirement = {
  check: async () => {
    return new Promise((resolve) => {
      Auth.onAuthStateChanged((authUser: User | null) => {
        if (!authUser) {
          resolve(false);
        } else {
          resolve(true);
        }
      });
    });
  },
  redirect: "/login",
};

const notSignedInRequirement: Requirement = {
  check: async () => {
    return new Promise((resolve) => {
      Auth.onAuthStateChanged((authUser: User | null) => {
        if (!authUser) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  },
  redirect: "/buyingflow",
};

const userPhoneVerifiedRequirement: Requirement = {
  check: async () => {
    try {
      const response = await getWithAuth(
        `${HTTP_PROTOCAL}://${AGNSY_SERVER_ADDRESS}/api/user/auth/phone`
      );
      const data = await response.json();

      return data.verified;
    } catch (error) {
      console.error("Error checking phone verification:", error);
      return false;
    }
  },
  redirect: "/send-phone-verification-code?action=account-verify",
};

const userNotAlreadyPhoneVerified: Requirement = {
  check: async () => {
    try {
      const response = await getWithAuth(
        `${HTTP_PROTOCAL}://${AGNSY_SERVER_ADDRESS}/api/user/auth/phone`
      );
      const data = await response.json();

      return !data.verified;
    } catch (error) {
      console.error("Error checking phone verification:", error);
      return false;
    }
  },
  redirect: "/buyingflow",
};

const userEmailVerifiedRequirement: Requirement = {
  check: async () => {
    try {
      const response = await getWithAuth(
        `${HTTP_PROTOCAL}://${AGNSY_SERVER_ADDRESS}/api/user/auth/email`
      );
      const data = await response.json();

      return data.verification.status;
    } catch (error) {
      console.error("Error checking user's email verification:", error);
      return false;
    }
  },
  redirect: `/verify-email`,
};

const userNotEmailVerifiedRequirement: Requirement = {
  check: async () => {
    try {
      const response = await getWithAuth(
        `${HTTP_PROTOCAL}://${AGNSY_SERVER_ADDRESS}/api/user/auth/email`
      );
      const data = await response.json();

      return !data.verification.status;
    } catch (error) {
      console.error("Error checking user's email verification:", error);
      return false;
    }
  },
  redirect: `/buyingflow`,
};

export {
  withRequirements,
  userSignedInRequirement,
  userPhoneVerifiedRequirement,
  userNotAlreadyPhoneVerified,
  notSignedInRequirement,
  userNotEmailVerifiedRequirement,
  userEmailVerifiedRequirement,
};
