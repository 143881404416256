import React, { createContext, ReactNode, useContext, useState } from "react";

export enum TOOLS {
  AI_CHAT = "AI_CHAT",
  AI_EMAIL = "AI_EMAIL",
  DISCLOSURE_ANALYSIS = "DISCLOSURE_ANALYSIS",
  OFFER_WRITIER = "OFFER_WRITER",
}

interface OptionalContext {
  initialPrompt?: string;
  analysisResults?: any;
  // Add more if we need them in the future :)
}

interface ToolContextProps {
  isToolOpen: boolean;
  currentTool: TOOLS | null;
  optionalContext: OptionalContext | null;
  openTool: (tool: TOOLS, optional?: OptionalContext) => void;
  closeTool: () => void;
}

const ToolContext = createContext<ToolContextProps | undefined>(undefined);

interface ToolProviderProps {
  children: ReactNode;
}

export const ToolProvider: React.FC<ToolProviderProps> = ({ children }) => {
  const [isToolOpen, setIsToolOpen] = useState(false);
  const [currentTool, setCurrentTool] = useState<TOOLS | null>(null);
  const [optionalContext, setOptionalContext] =
    useState<OptionalContext | null>(null);

  const openTool = (tool: TOOLS, optional?: OptionalContext) => {
    setCurrentTool(tool);
    setOptionalContext(optional || null);
    setIsToolOpen(true);
  };

  const closeTool = () => {
    setCurrentTool(null);
    setOptionalContext(null);
    setIsToolOpen(false);
  };

  return (
    <ToolContext.Provider
      value={{ isToolOpen, currentTool, optionalContext, openTool, closeTool }}
    >
      {children}
    </ToolContext.Provider>
  );
};

export const useToolContext = () => {
  const context = useContext(ToolContext);
  if (!context) {
    throw new Error("useToolContext must be used within a ToolProvider");
  }
  return context;
};
