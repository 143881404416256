/* React */
import React, { useState } from "react";
import ReactMarkdown from "react-markdown";

/* Data Model */
import { DisclosurePackageAnalysisComponentType } from "../../../../../model/Core/AI";
import { AnalysisResultsProps } from "../../Steps/OfferNegotiation/model/AnalysisResults";

/* Functionality */
import remarkGfm from "remark-gfm";

/* Styling */
import "./ai-disclosure-analysis.css";
import DropdownArrow from "../../../../../assets/disclosure-dropdown.svg";

const AnalysisResults: React.FC<AnalysisResultsProps> = ({
  analysisResults,
}) => {
  /* 
  ================================================================
  STATE VARS
  ================================================================
  */
  const [openDropdown, setOpenDropdown] =
    useState<DisclosurePackageAnalysisComponentType | null>(null);

  /* 
  ================================================================
  EVENT HANDLER: Drop down
  ================================================================
  */
  const toggleDropdown = (type: DisclosurePackageAnalysisComponentType) => {
    setOpenDropdown(openDropdown === type ? null : type);
  };

  /* 
  ================================================================
  RENDER
  ================================================================
  */
  return (
    <div className="disclosure-package__analysis-results">
      {Object.entries(analysisResults.result).map(([type, component]) => (
        <div key={type} className="disclosure-package__dropdown">
          <div
            className={`disclosure-package__dropdown-button ${
              openDropdown === type
                ? "disclosure-package__dropdown-button--open"
                : ""
            }`}
            onClick={() =>
              toggleDropdown(type as DisclosurePackageAnalysisComponentType)
            }
          >
            <span className="disclosure-package__dropdown-title">
              {type.toUpperCase()}
            </span>
            <img
              src={DropdownArrow}
              alt="dropdown arrow"
              className={`disclosure-package__dropdown-arrow ${
                openDropdown === type
                  ? "disclosure-package__dropdown-arrow--open"
                  : ""
              }`}
            />
          </div>

          {openDropdown === type && (
            <div className="disclosure-package__dropdown-content">
              <div className="disclosure-package__analysis-item">
                <h4>{type} Details</h4>
                <div className="markdown-content">
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>
                    {component.analysis}
                  </ReactMarkdown>
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default AnalysisResults;
