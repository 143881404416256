/* React */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

/* Functionality */
import { logoutCurrentUser } from "../../../APIs/user";
import { Auth } from "../../../firebase/authentication";
import { User } from "firebase/auth";

/* Styling */
import "./header.css";
import HeaderLogo from "../../../assets/header_agnsy_logo.svg";

const Header: React.FC = () => {
  /* 
  ================================================================
  CONSTANTS
  ================================================================
  */
  const navigate = useNavigate();

  /* 
  ================================================================
  STATE VARS
  ================================================================
  */
  const [signedIn, setSignedIn] = useState(false);

  /* 
  ================================================================
  HOOK: user auth
  ================================================================
  */
  useEffect(() => {
    const unsubscribe = Auth.onAuthStateChanged((authUser: User | null) => {
      const userAuthenticated = !!authUser;
      setSignedIn(userAuthenticated);
      localStorage.setItem("isAuthenticated", userAuthenticated.toString());
    });
    return () => {
      unsubscribe();
    };
  }, []);

  /* 
  ================================================================
  EVENT HANDLER: Logout
  ================================================================
  */
  const handleLogout = () => {
    logoutCurrentUser().then(() => {
      navigate("/");
    });
  };

  /* 
  ================================================================
  RENDER
  ================================================================
  */
  return (
    <header className="agnsy-header">
      <div className="agnsy-header__content">
        <Link to="/">
          <img
            className="agnsy-header__logo"
            src={HeaderLogo}
            alt="Agensy Logo"
          />
        </Link>

        <div className="agnsy-header__right">
          <Link to="/buyingflow/homesearch" className="agnsy-header__text">
            Home Search
          </Link>
          <div
            className="agnsy-header__text"
            onClick={() => alert("Available after MVP!")}
          >
            AI Tools
          </div>
          <div
            className="agnsy-header__text"
            onClick={() => alert("Available after MVP!")}
          >
            Guides
          </div>
          {signedIn && (
            <Link to="/" className="agnsy-header__text" onClick={handleLogout}>
              Logout
            </Link>
          )}
          {signedIn ? (
            <Link to="/dashboard" className="agnsy-header__my-homes">
              My Homes
            </Link>
          ) : (
            <Link to="/login" className="agnsy-header__my-homes">
              Login
            </Link>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
