import React, { useState, useEffect } from "react";
import { buyingFlowSteps } from "./steps";
import "./buyingflow-nav.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  buyingFlowNumberStepMapping,
  buyingFlowStepNumberMapping,
  BUYING_FLOW_STEP,
} from "../Steps/StepComponents";

interface BuyingFlowNavButtonProps {
  step: number;
  title: string;
  icon: string;
  completeIcon: string;
  isCurrent: boolean;
  isCompleted: boolean;
  isExpanded: boolean;
  onClick?: () => void;
}

interface BuyingFlowNavProps {
  step: BUYING_FLOW_STEP;
}

const BuyingFlowNavButton: React.FC<BuyingFlowNavButtonProps> = ({
  step,
  title,
  icon,
  completeIcon,
  isCurrent,
  isCompleted,
  isExpanded,
  onClick,
}) => {
  return (
    <div
      className={`buyingflow-nav-btn ${
        isCurrent ? "buyingflow-nav-btn--current" : ""
      }`}
      onClick={onClick}
    >
      {isExpanded && (
        <div
          className={`buyingflow-nav-btn__text-container ${
            isCompleted ? "buyingflow-nav-btn--completed" : ""
          }`}
        >
          <h3 className="buyingflow-nav-btn__step">Step {step}</h3>
          <p className="buyingflow-nav-btn__title">{title}</p>
        </div>
      )}
      <div className="buyingflow-nav-btn__icon-container">
        <img
          className="buyingflow-nav-btn__icon"
          src={isCompleted ? completeIcon : icon}
          alt={`Step ${step} - ${title}`}
        />
      </div>
    </div>
  );
};

const BuyingFlowNav: React.FC<BuyingFlowNavProps> = ({ step }) => {
  // management variables
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const flowId = searchParams.get("flow")?.replace(/^"|"$/g, "");
  const flowIdParameter = flowId ? `?flow=${flowId}` : "";

  const [currentStep, setCurrentStep] = useState<number>(
    buyingFlowStepNumberMapping[step]
  );
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  //TODO: I am assuming this is going to be stored in a DB so we can set it
  // when page loads

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [completedSteps, setCompletedSteps] = useState<boolean[]>([
    true,
    false,
    false,
    false,
    false,
  ]);

  // Contract the navigation bar after a couple of seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsExpanded(false);
    }, 3500);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    setCurrentStep(buyingFlowStepNumberMapping[step]);
  }, [step]);

  const handleStepClick = (index: number) => {
    setCurrentStep(index);
    navigate(
      `/buyingflow/${buyingFlowNumberStepMapping[index + 1]}` +
        (index !== 0 ? flowIdParameter : "") // Don't add flow parameter when you go back to home search
    );
  };

  return (
    <div
      className={`buyingflow-nav
        ${
          isExpanded ? "buyingflow-nav--expanded" : "buyingflow-nav--contracted"
        }`}
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
    >
      {buyingFlowSteps.map((stepItem, index) => (
        <BuyingFlowNavButton
          key={index}
          step={index + 1}
          title={stepItem.title}
          icon={stepItem.icon}
          completeIcon={stepItem.completeIcon}
          isCurrent={index === currentStep}
          isCompleted={completedSteps[index]}
          isExpanded={isExpanded}
          onClick={() => handleStepClick(index)}
        />
      ))}
    </div>
  );
};

export default BuyingFlowNav;
