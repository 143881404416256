// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBo3m1OdgX1PfR5t8a_MTXa9iy-2unGM3A",
  authDomain: "agnsy-dev.firebaseapp.com",
  projectId: "agnsy-dev",
  storageBucket: "agnsy-dev.firebasestorage.app",
  messagingSenderId: "1084448560562",
  appId: "1:1084448560562:web:7806a2bc7deb2887a200ab",
  measurementId: "G-5DJ9V579BP",
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebaseApp);
