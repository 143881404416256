import React, { useState } from "react";
import "./Dashboard.css";
import { userSignedInRequirement, withRequirements } from "../requirements";

import propertyImg from "../../assets/dashboard_property_preview.png";
import avatarImg from "../../assets/kia_avatar.svg";
import clipboard from "../../assets/dashboard_clipboard.svg";

interface DashboardProps {
  // Add any props here if needed
}

const Dashboard: React.FC<DashboardProps> = () => {
  const [activeTab, setActiveTab] = useState("feed");

  return (
    <div className="dashboard">
      <main className="dashboard-main-content">
        <div className="dashboard-left-panel">
          <PropertyCard />
          <TabPanel activeTab={activeTab} setActiveTab={setActiveTab} />
        </div>
        <div className="dashboard-right-panel">
          <UpcomingEvents />
          <HomeProgress />
          <PropertyMatches />
        </div>
      </main>
    </div>
  );
};

const PropertyCard = () => {
  return (
    <div className="dashboard-property-card">
      <div className="dashboard-date-banner">
        <p>Wed, Feb 19</p>
        <h2>Welcome back!</h2>
      </div>
      <div className="dashboard-property-image-container">
        <button className="dashboard-arrow-btn left">
          <span>&#10094;</span>
        </button>
        <img
          src={propertyImg}
          alt="Property exterior"
          className="dashboard-property-image"
        />
        <button className="dashboard-arrow-btn right">
          <span>&#10095;</span>
        </button>
      </div>
      <div className="dashboard-property-details">
        <div className="dashboard-address-info">
          <h3>123 Bubb Rd, Cupertino, CA</h3>
          <button className="dashboard-expand-btn">&#9660;</button>
        </div>
        <div className="dashboard-property-specs">
          <span>3 bd</span> | <span>2 ba</span> | <span>1,200 sqft</span>
        </div>
      </div>
    </div>
  );
};

interface TabPanelProps {
  activeTab: string;
  setActiveTab: (tab: string) => void;
}

const TabPanel: React.FC<TabPanelProps> = ({ activeTab, setActiveTab }) => {
  return (
    <div className="dashboard-tab-panel">
      <div className="dashboard-tab-header">
        <button
          className={`dashboard-tab-btn ${
            activeTab === "feed" ? "active" : ""
          }`}
          onClick={() => setActiveTab("feed")}
        >
          Feed
        </button>
        <span className="dashboard-update-count">3 updates</span>
        <button className="dashboard-sound-btn">&#128266;</button>
      </div>

      {activeTab === "feed" && (
        <div className="dashboard-feed-content">
          <ActivityItem
            type="counter-offer"
            title="Counter Offer Received"
            address="123 Bubb Rd, Cupertino, CA 95014"
            time="45 minutes ago"
            hasAction={true}
            actionText="Review"
          />
          <ActivityItem
            type="price-change"
            title="Listing Price Reduction"
            address="123 Bubb Rd, Cupertino, CA 95014"
            time="3 hours ago"
            priceChange="-$100,000"
          />
          <ActivityItem
            type="document"
            title="Updated Pre-Approval Letter"
            from="From Omid Hodaei, Chase"
            time="1 week ago"
            hasAction={true}
            actionText="Review"
          />

          <ContactsSection />
          <VendorsSection />
        </div>
      )}
    </div>
  );
};

interface ActivityItemProps {
  type: string;
  title: string;
  address?: string;
  time: string;
  hasAction?: boolean;
  actionText?: string;
  priceChange?: string;
  from?: string;
}

const ActivityItem: React.FC<ActivityItemProps> = ({
  type,
  title,
  address,
  time,
  hasAction,
  actionText,
  priceChange,
  from,
}) => {
  return (
    <div className="dashboard-activity-item">
      <div className="dashboard-activity-thumbnail">
        <img src={clipboard} alt={title} />
      </div>
      <div className="dashboard-activity-details">
        <h4>{title}</h4>
        {address && <p className="dashboard-activity-address">{address}</p>}
        {from && <p className="dashboard-activity-from">{from}</p>}
        <p className="dashboard-activity-time">{time}</p>
      </div>
      <div className="dashboard-activity-action">
        {hasAction && (
          <button className="dashboard-action-btn">{actionText}</button>
        )}
        {priceChange && (
          <span className="dashboard-price-change">{priceChange}</span>
        )}
      </div>
    </div>
  );
};

const ContactsSection = () => {
  return (
    <div className="dashboard-contacts-section">
      <div className="dashboard-section-header">
        <h3>Contacts</h3>
        <button className="dashboard-email-all-btn">Email all</button>
      </div>
      <p className="dashboard-team-label">My team</p>
      <div className="dashboard-contacts-list">
        <ContactItem name="Kai Al" role="My agent" hasCallBtn={true} />
        <ContactItem
          name="Christian Aguilar"
          role="Seller Agent for 123 Bubb Road"
          email="christian@realty.com"
          phone="949.123.4567"
        />
        <ContactItem
          name="Christian Aguilar"
          role="Lender Agent"
          email="christian@realty.com"
          phone="949.123.4567"
        />
        <ContactItem
          name="Christian Aguilar"
          role="Escrow officer"
          email="christian@realty.com"
          phone="949.123.4567"
        />
      </div>
    </div>
  );
};

interface ContactItemProps {
  name: string;
  role: string;
  email?: string;
  phone?: string;
  hasCallBtn?: boolean;
}

const ContactItem: React.FC<ContactItemProps> = ({
  name,
  role,
  email,
  phone,
  hasCallBtn,
}) => {
  return (
    <div className="dashboard-contact-item">
      <div className="dashboard-contact-avatar">
        <img src={avatarImg} alt={name} />
      </div>
      <div className="dashboard-contact-info">
        <h4>{name}</h4>
        <p className="dashboard-contact-role">{role}</p>
        {email && <p className="dashboard-contact-email">{email}</p>}
        {phone && <p className="dashboard-contact-phone">{phone}</p>}
      </div>
      {hasCallBtn && <button className="dashboard-call-btn">Call</button>}
    </div>
  );
};

const VendorsSection = () => {
  return (
    <div className="dashboard-vendors-section">
      <button className="dashboard-explore-vendors-btn">Explore vendors</button>
    </div>
  );
};

const UpcomingEvents = () => {
  return (
    <div className="dashboard-upcoming-events">
      <h3>Up next</h3>
      <div>
        <EventItem
          title="123 Bubb Rd Open House"
          date="Feb 20, 1 PM - 4 PM PST"
          hasAction={true}
          actionText="Directions"
        />
        <EventItem
          title="123 Bubb Rd Offer Deadline"
          date="Feb 21, 4 PM PST"
          hasAction={true}
          actionText="Start Offer"
        />
      </div>
    </div>
  );
};

interface EventItemProps {
  title: string;
  date: string;
  hasAction?: boolean;
  actionText?: string;
}

const EventItem: React.FC<EventItemProps> = ({
  title,
  date,
  hasAction,
  actionText,
}) => {
  return (
    <div className="dashboard-event-item">
      <div className="dashboard-event-marker"></div>
      <div className="dashboard-event-details">
        <h4>{title}</h4>
        <p className="dashboard-event-date">{date}</p>
      </div>
      {hasAction && (
        <button className="dashboard-event-action-btn">{actionText}</button>
      )}
    </div>
  );
};

const HomeProgress = () => {
  return (
    <div className="dashboard-home-progress">
      <h3>Mat's New Home</h3>
      <div className="dashboard-progress-timeline">
        <ProgressItem title="Pre-approval" isComplete={true} />
        <ProgressItem
          title="Search"
          isActive={true}
          subtitle="Adjust preferences"
        />
        <ProgressItem title="Offer" isComplete={false} />
        <ProgressItem title="Escrow" isComplete={false} />
        <ProgressItem title="Closing" isComplete={false} />
      </div>
    </div>
  );
};

interface ProgressItemProps {
  title: string;
  isComplete?: boolean;
  isActive?: boolean;
  subtitle?: string;
}

const ProgressItem: React.FC<ProgressItemProps> = ({
  title,
  isComplete,
  isActive,
  subtitle,
}) => {
  return (
    <div
      className={`dashboard-progress-item ${isComplete ? "complete" : ""} ${
        isActive ? "active" : ""
      }`}
    >
      <div className="dashboard-progress-marker">
        {isComplete && <span className="dashboard-checkmark">✓</span>}
        {isActive && <span className="dashboard-loading-icon">⟳</span>}
      </div>
      <div className="dashboard-progress-label">
        <h4>{title}</h4>
        {subtitle && <p className="dashboard-progress-subtitle">{subtitle}</p>}
      </div>
    </div>
  );
};

const PropertyMatches = () => {
  return (
    <div className="dashboard-property-matches">
      <div className="dashboard-matches-grid">
        <PropertyMatchItem
          price="$3,098,000"
          beds={4}
          baths={3}
          sqft={2740}
          address="123 Bubb Rd, Cupertino, CA 95014"
        />
        <PropertyMatchItem showImage={true} />
      </div>
      <button className="dashboard-view-all-btn">View all matches (17)</button>
    </div>
  );
};

interface PropertyMatchItemProps {
  price?: string;
  beds?: number;
  baths?: number;
  sqft?: number;
  address?: string;
  showImage?: boolean;
}

const PropertyMatchItem: React.FC<PropertyMatchItemProps> = ({
  price,
  beds,
  baths,
  sqft,
  address,
  showImage = true,
}) => {
  return (
    <div className="dashboard-property-match-item">
      {showImage && (
        <img
          src={propertyImg}
          alt="Property"
          className="dashboard-match-image"
        />
      )}
      {price && (
        <div className="dashboard-match-details">
          <h4 className="dashboard-match-price">{price}</h4>
          <p className="dashboard-match-specs">
            {beds} beds | {baths} baths | {sqft} sqft
          </p>
          <p className="dashboard-match-address">{address}</p>
        </div>
      )}
    </div>
  );
};

export default withRequirements(Dashboard, [userSignedInRequirement]);
