import { useEffect, useState } from "react";
import { postWithAuth } from "../../../firebase/authentication";
import {
  userNotEmailVerifiedRequirement,
  userSignedInRequirement,
  withRequirements,
} from "../../requirements";
import { useNavigate } from "react-router-dom";

import emailIcon from "../../../assets/email_verification_email_icon.svg";

const AGNSY_API_BASE_URL = process.env.REACT_APP_AGNSY_SERVER_ADDRESS;
const HTTP_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";

const VerifyEmail: React.FC = () => {
  const [message, setMessage] = useState<string>("");
  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    const sendVerifificationEmailWrapper = async () => {
      const tempMessage: string = await sendVerifificationEmail();
      if (tempMessage === "ok") {
        setMessage("Verification email successfully sent to inbox.");
      } else {
        setMessage(tempMessage);
      }
    };

    sendVerifificationEmailWrapper();
  }, [navigate]);

  const handleChange = (index: number, value: string) => {
    if (code.some((digit) => digit === "")) {
      setMessage("");
    }
    if (value.length <= 1) {
      const newCode = [...code];
      newCode[index] = value.replace(/\D/g, "");
      setCode(newCode);

      if (value && index < 5) {
        const nextInput = document.getElementById(`input-${index + 1}`);
        if (nextInput) {
          nextInput.focus();
        }
      }
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    const stringCode = code.join("");
    formData.append("code", code.join(""));

    try {
      const verifyResponse = await verifyEmailCode(stringCode);
      if (verifyResponse === "ok") {
        navigate("/buyingflow");
      } else {
        setMessage(verifyResponse);
      }
    } catch (error) {
      console.error("Error during email code verification:", error);
      setMessage("An error occurred during verification. Please try again.");
    }
    setIsLoading(false);
  };

  const handleResendCode = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();

    try {
      let tempMessage = await sendVerifificationEmail();

      if (tempMessage === "ok") {
        setMessage("Message successfully resent.");
      } else {
        setMessage(tempMessage);
      }
    } catch (error) {
      console.error("Error sending verification email:", error);
    }
  };

  const disableSubmission = code.some((digit) => digit === "");

  return (
    <div className="phone_verification__container">
      <div className="phone_verification__form">
        <img
          className="email_verification__email_icon"
          src={emailIcon}
          alt="Phone Verification Icon"
        />

        <h2 className="phone_verification__title">Email Verification</h2>

        <form>
          <div className="phone_verification__subtitle">
            Enter your six-digit code here
          </div>

          <div className="phone_verification__inputs">
            {code.map((digit, index) => (
              <input
                key={index}
                id={`input-${index}`}
                className="phone_verification__input"
                type="text"
                value={digit}
                onChange={(e) => handleChange(index, e.target.value)}
                maxLength={1}
              />
            ))}
          </div>

          <div>
            {message && (
              <p
                className={`phone_verification__alert ${
                  message === "Message successfully resent." ||
                  message === "Verification email successfully sent to inbox."
                    ? "phone_verification__alert--phone-resent-success"
                    : "phone_verification__alert--phone-verification-error"
                }`}
              >
                {message}
              </p>
            )}
          </div>

          <div className="phone_verification__button-container">
            {isLoading ? (
              <div>loading...</div>
            ) : (
              <button
                className="phone_verification__button"
                onClick={handleSubmit}
                disabled={disableSubmission}
              >
                Verify
              </button>
            )}
          </div>

          <div className="phone_verification__resend-container">
            Didn't receive the code?{" "}
            <button
              className="phone_verification__resend-button"
              onClick={handleResendCode}
            >
              Resend Code
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export async function sendVerifificationEmail(): Promise<string> {
  try {
    const response = await postWithAuth(
      `${HTTP_PROTOCAL}://${AGNSY_API_BASE_URL}/api/user/auth/email/send-verification-code`
    );

    if (response.ok) {
      return "ok";
    } else {
      switch (response.status) {
        case 401: //  Token for user is invalid or expired
          return "User in invalid authentication. Logout and sign back in.";
        case 404:
          return "User doesn't exist";
        case 409:
          return "Email already verified with another account";
        case 500:
          return "Failed to send verification email";
        default:
          console.error(`Unexpected error occurred: ${response.status}`);
          return "Unexpected error occurred.";
      }
    }
  } catch (error) {
    return `Error sending verification email: ${(error as Error).message}`;
  }
}

export async function verifyEmailCode(code: string): Promise<string> {
  const formData = new FormData();
  formData.append("code", code);

  try {
    const response = await postWithAuth(
      `${HTTP_PROTOCAL}://${AGNSY_API_BASE_URL}/api/user/auth/email/verify-code`,
      formData
    );

    if (response.ok) {
      return "ok";
    } else {
      const errorData = await response.json();
      switch (response.status) {
        case 400:
          return errorData.detail;
        case 401: //  Token for user is invalid or expired
          return "User in invalid authentication. Logout and sign back in.";
        case 404:
          return "User doesn't exist";
        case 409:
          return "Email already verified with another account";
        case 500:
          return "Failed to send verification code.";
        default:
          console.error(`Unexpected error occurred: ${response.status}`);
          return "Unexpected error occurred.";
      }
    }
  } catch (error) {
    return `Error sending verification code: ${(error as Error).message}`;
  }
}

export default withRequirements(VerifyEmail, [
  userSignedInRequirement,
  userNotEmailVerifiedRequirement,
]);
