import { useEffect, useState } from "react";
import { PriceDetermination } from "../../../../../../model/Core/AI";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  fetchPriceDetermination,
  setPriceInFlow,
} from "../../../../../../APIs/user";

import "./PriceDetermination.css";

const PriceDeterminationStep: React.FC = () => {
  /* 
  ================================================================
  CONSTANTS
  ================================================================
  */
  const [searchParams] = useSearchParams();
  const flowId = searchParams.get("flow")?.replace(/^"|"$/g, "");
  const flowIdParam = flowId ? `?flow=${flowId}` : "";
  const navigate = useNavigate();

  /* 
  ================================================================
  STATE VARIABLES
  ================================================================
  */
  const [priceDetermination, setPriceDetermination] =
    useState<PriceDetermination | null>(null);

  /* 
  ================================================================
  SET PRICE
  ================================================================
  */
  const setPrice = async (value: number) => {
    if (flowId) {
      await setPriceInFlow(flowId, value);
      navigate(`/buyingflow/offer-negotiation/offer-writing${flowIdParam}`);
    } else {
      navigate("/dashboard");
    }
  };

  /* 
  ================================================================
  HOOK: Initial
  ================================================================
  */
  useEffect(() => {
    const fetchData = async () => {
      if (flowId) {
        let priceDeterminationFetch = await fetchPriceDetermination(flowId);
        if (priceDeterminationFetch) {
          setPriceDetermination(priceDeterminationFetch);
        }
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* 
  ================================================================
  RENDER
  ================================================================
  */
  const priceOptions = [
    { key: "low", label: "Let's play risky", option: priceDetermination?.low },
    {
      key: "suggested",
      label: "Our recommendation",
      option: priceDetermination?.suggested,
    },
    {
      key: "high",
      label: "Let's secure the home",
      option: priceDetermination?.high,
    },
  ] as const;

  return (
    <div>
      <p className="comparable-homes__description">
        Determining some price options...
      </p>

      {/* Step 1: Determine price */}
      {priceDetermination === null ? (
        <div className="comparable-homes__loading">
          <div className="comparable-homes__spinner"></div>
        </div>
      ) : (
        <>
          <p className="comparable-homes__description">
            Here are some options I am thinking:
          </p>
          <div className="price-determination__options">
            {priceOptions.map(({ key, label, option }) => {
              if (!option) return <div key={key} />;
              const isSuggested = key === "suggested";

              return (
                <div key={key} className="price-determination__wrapper">
                  <p className="price-determination__label">{label}</p>

                  <div
                    className={`price-determination__box ${
                      isSuggested
                        ? "price-determination__box--suggested"
                        : "price-determination__box--regular"
                    }`}
                    onClick={() => setPrice(option.value)}
                  >
                    <p className="price-determination__value">
                      ${option.value.toLocaleString()}
                    </p>
                    <div className="price-determination__tooltip">
                      <span className="price-determination__info-icon">?</span>
                      <span className="price-determination__tooltip-text">
                        {option.reasoning}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default PriceDeterminationStep;
