import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Chat from "../../../../../../assets/chat.svg";
import "./Overview.css"

// Offer and Negotiation Overview step
const OfferNegotiationOverview: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const flowId = searchParams.get("flow");

  const handleClick = () => {
    // Preserve the flow parameter if it exists
    const flowParam = flowId ? `?flow=${flowId}` : "";
    navigate(`/buyingflow/offer-negotiation/disclosure-analysis${flowParam}`);
  };

  return (
    <div className="overview">
        {/* Introduction Section */}
      <section className="overview__intro">
        <p className="overview__description">
          Making an offer on a home requires some research:
        </p>
      </section>

      {/* Steps Section */}
      <section className="overview__steps">
        <div className="overview__step-item">
          <text className="overview__step-name">
            Disclosure analysis
          </text>{" "}
          - analyze the home's disclosures; see if there are any notable mishaps on the property
        </div>
        <div className="overview__step-item">
          <text className="overview__step-name">
            Comparable Sales
          </text>{" "}
          - analyze recent nearby home transactions to get a feel on what homes in this neighborhood go for
        </div>
        <div className="overview__step-item">
          <text className="overview__step-name">
            Reconnaissance
          </text>{" "}
          - reach out to the sellers agent to evaluate the level of buyers interest on the home
        </div>
      </section>

      {/* Chat Suggestions */}
      <section className="overview__chats">
        <div className="overview__chat-suggestions">
          <div className="introduction__chat">
            <img className="introduction__chat-icon" src={Chat} alt="chat" />
          </div>
          <div className="overview__chat-suggestion">
            <img
              className="overview__chat-suggestion-icon"
              src={Chat}
              alt="chat"
            />
            What are home disclosures?
          </div>
          <div className="overview__chat-suggestion">
            <img
              className="overview__chat-suggestion-icon"
              src={Chat}
              alt="chat"
            />
            What are nearby homes?
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="overview__cta">
        <button className="overview__cta-button" onClick={handleClick}>
          Next
        </button>
      </section>
    </div>
  );
};

export default OfferNegotiationOverview;
