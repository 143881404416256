import {
  userEmailVerifiedRequirement,
  userPhoneVerifiedRequirement,
  userSignedInRequirement,
  withRequirements,
} from "../../../../requirements";

function Closing() {
  return <div className="budgeting-component">hi</div>;
}

export default withRequirements(Closing, [
  userSignedInRequirement,
  userEmailVerifiedRequirement,
  userPhoneVerifiedRequirement,
]);
