import React, { useEffect, useState, useRef } from "react";
import UploadArrow from "../../../../../../assets/upload-arrow.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
// import AIViewTool from "./AIView";
import AISparkles from "../../../../../../assets/ai_sparkles.svg";
import Checkmark from "../../../../../../assets/checkmark_done.svg";
import { Home } from "../../../../../../model/Core/Home";

import "./DisclosureAnalysis.css";
import {
  fetchCurrentUserData,
  fetchHomeData,
} from "../../../../../../APIs/user";
import { UUIDTypes } from "uuid";
import { DisclosurePackageAnalysis } from "../../../../../../model/Core/AI";
import { TOOLS, useToolContext } from "../../../Tools/ToolViewerContext";

const AGNSY_API_BASE_URL = process.env.REACT_APP_AGNSY_SERVER_ADDRESS;
const HTTP_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";

// API function to fetch disclosure analysis for the current home
const fetchDisclosureAnalysis = async (
  homeId: UUIDTypes
): Promise<DisclosurePackageAnalysis> => {
  const formData = new FormData();
  formData.append("home_id", homeId as string);

  try {
    const response = await fetch(
      `${HTTP_PROTOCAL}://${AGNSY_API_BASE_URL}/api/ai/disclosure-analysis`,
      {
        method: "POST",
        body: formData,
      }
    );

    if (!response.ok) {
      throw new Error(
        `Failed to fetch disclosure analysis: ${response.status}`
      );
    }

    return (await response.json()) as DisclosurePackageAnalysis;
  } catch (error) {
    console.error("Error fetching disclosure analysis:", error);
    throw error;
  }
};

// API function to handle file uploads
const uploadDisclosurePackage = async (
  files: File[]
): Promise<DisclosurePackageAnalysis> => {
  // Only use the first ZIP file if multiple are selected
  const zipFile = files.find((file) =>
    file.name.toLowerCase().endsWith(".zip")
  );

  if (!zipFile) {
    throw new Error("No ZIP file found");
  }

  try {
    const formData = new FormData();
    // Just append one file with the key 'zip'
    formData.append("zip", zipFile);

    const response = await fetch(
      `${HTTP_PROTOCAL}://${AGNSY_API_BASE_URL}/api/ai/disclosure-analysis/from-zip`,
      {
        method: "POST",
        body: formData,
      }
    );

    if (response.status === 400) {
      const errorData = await response.json();
      throw new Error(errorData.detail || "Invalid file format");
    }

    if (!response.ok) {
      throw new Error(`Upload failed with status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error uploading disclosure package:", error);
    throw error;
  }
};

const DEFAULT_HOME_ID = "0224a838-4dfb-41e9-94a4-6607b1f3ad95";
//const DEFAULT_HOME_ID = "0224a838-4dfb-41e9-9";

// Offer and Negotiation Overview step
const OfferNegotiationDisclosureAnalysis: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchStatus, setSearchStatus] = useState<
    "searching" | "successful" | "unsuccessful"
  >("searching");
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [analysisResults, setAnalysisResults] = useState<any>(null);
  const [analysisComplete, setAnalysisComplete] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [currentHomeId, setCurrentHomeId] = useState<UUIDTypes | null>(null);
  const [homeData, setHomeData] = useState<Home | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formattedAddress, setFormattedAddress] = useState<string>("");

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const flowId = searchParams.get("flow");

  const { openTool } = useToolContext();

  // Extract homeId from current flow context or use default
  useEffect(() => {
    const fetchCurrentHomeId = async () => {
      try {
        // If flow ID exists, try to get the home ID from user data
        if (flowId) {
          const userData = await fetchCurrentUserData();

          if (userData) {
            const buyingFlowData =
              userData.buying_flows.find((flow) => flow.id === flowId) || null;

            if (buyingFlowData && buyingFlowData.home_id) {
              setCurrentHomeId(buyingFlowData.home_id);
              return;
            }
          }
        }

        // If we get here, either no flow ID, no user data, or no home ID was found
        // Use the default home ID instead
        setCurrentHomeId(DEFAULT_HOME_ID);
        console.log("DEFAULT ID");
      } catch (error) {
        console.error("Error fetching current home ID:", error);
        // Fall back to default home ID on error
        setCurrentHomeId(DEFAULT_HOME_ID);
      }
    };

    fetchCurrentHomeId();
  }, [flowId]);

  // Fetch home data once we have the home ID
  useEffect(() => {
    if (!currentHomeId) return;

    const getHomeData = async () => {
      try {
        const home = await fetchHomeData(currentHomeId);
        if (home) {
          setHomeData(home);

          // Create formatted address string
          const address = home.address;
          let formatted = address.address_line_1;
          if (address.address_line_2) {
            formatted += `, ${address.address_line_2}`;
          }
          formatted += `, ${address.city}, ${address.state} ${address.zip_code}`;
          setFormattedAddress(formatted);
        }
      } catch (error) {
        console.error("Error fetching home data:", error);
      }
    };

    getHomeData();
  }, [currentHomeId]);

  // Fetch disclosure analysis once we have the home ID
  useEffect(() => {
    if (!currentHomeId) return;

    // Try to fetch the disclosure analysis for the current home
    const fetchAnalysis = async () => {
      try {
        setSearchStatus("searching");
        setLoading(true);

        const results = await fetchDisclosureAnalysis(currentHomeId);
        setAnalysisResults(results);
        setAnalysisComplete(true);
        setSearchStatus("successful");
      } catch (error) {
        console.error("Failed to fetch disclosure analysis:", error);
        setSearchStatus("unsuccessful");
      } finally {
        setLoading(false);
      }
    };

    fetchAnalysis();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentHomeId]);

  const handleSkipClick = () => {
    const flowIdParam = flowId ? `?flow=${flowId}` : "";
    navigate(`/buyingflow/offer-negotiation/comparable-homes${flowIdParam}`);
  };

  const handleUploadClick = () => {
    // Trigger the hidden file input when button is clicked
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      const fileArr = Array.from(event.dataTransfer.files);
      await handleFileUpload(fileArr);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    if (event.target.files && event.target.files[0]) {
      const fileArr = Array.from(event.target.files);
      await handleFileUpload(fileArr);
    }
  };

  const handleFileUpload = async (files: File[]) => {
    // Check if at least one file is a ZIP
    const zipFile = files.find((file) =>
      file.name.toLowerCase().endsWith(".zip")
    );
    if (!zipFile) {
      setUploadError("Please upload a ZIP file.");
      return;
    }

    setUploading(true);
    setUploadError(null);

    try {
      const results = await uploadDisclosurePackage([zipFile]);
      setAnalysisResults(results);
      setAnalysisComplete(true);
    } catch (error) {
      console.error("Error uploading file:", error);
      setUploadError(
        `Upload failed: ${
          error instanceof Error ? error.message : "Unknown error"
        }`
      );
    } finally {
      setUploading(false);
    }
  };

  const handleContinueClick = () => {
    const flowIdParam = flowId ? `?flow=${flowId}` : "";
    navigate(`/buyingflow/offer-negotiation/comparable-homes${flowIdParam}`, {
      state: { disclosureAnalysis: analysisResults },
    });
  };

  const getDisplayAddress = () => {
    if (!homeData || !homeData.address) {
      return "the property";
    }

    return homeData.address.address_line_1;
  };

  return (
    <div className="disclosure-package">
      <div className="disclosure-package__content">
        <p className="disclosure-package__description">
          Let's do disclosure analysis to find if anything is wrong with the
          home.
        </p>

        <p className="disclosure-package__search-status">
          Searching on the web for the disclosure package relating to{" "}
          {getDisplayAddress()}
        </p>

        {loading ? (
          <div className="disclosure-package__loading">
            <div className="disclosure-package__spinner"></div>
            <p>
              Please wait while we search for disclosure documents and analyze
              them...
            </p>
          </div>
        ) : analysisComplete ? (
          <div className="disclosure-package__analysis-complete">
            <img
              className="disclosure-package__checkmark-image"
              src={Checkmark}
              alt="check-done"
            />
            <p className="disclosure-package__search-status">
              Analysis is complete and ready for review. Proceed to the next
              step when ready.
            </p>
            <div className="disclosure-package__actions">
              <button
                className="disclosure-package__review-btn"
                // onClick={openAIView}
                onClick={() =>
                  openTool(TOOLS.DISCLOSURE_ANALYSIS, {
                    analysisResults: analysisResults,
                  })
                }
              >
                <span className="disclosure-package__ai-icon">
                  <img
                    className="disclosure-package__ai-image"
                    src={AISparkles}
                    alt="ai_sparkles"
                  />
                </span>
                Review
              </button>
              <button
                className="disclosure-package__continue-btn"
                onClick={handleContinueClick}
              >
                Continue to Next Step
              </button>
            </div>
            {/* AI View Tool */}
          </div>
        ) : (
          <>
            <div className="disclosure-package__status">
              <span className="disclosure-package__status-icon">⨯</span>
              <span className="disclosure-package__status-text">
                Unsuccessful
              </span>
            </div>

            <p className="disclosure-package__message">
              Unable to find a disclosure package. If you have one on hand for
              this property, please upload. If not, we can skip.
            </p>

            <div
              className="disclosure-package__upload-area"
              onDragOver={handleDragOver}
              onDrop={handleFileDrop}
            >
              <div className="disclosure-package__actions">
                <input
                  ref={fileInputRef}
                  type="file"
                  accept=".zip"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <button
                  className="disclosure-package__upload-btn"
                  onClick={handleUploadClick}
                  disabled={uploading}
                >
                  <span className="disclosure-package__upload-icon">
                    <img
                      className="disclosure-package__upload-arrow"
                      src={UploadArrow}
                      alt="arrow"
                    />
                  </span>
                  {uploading ? "Uploading..." : "Disclosure Package"}
                </button>
                <div
                  className="disclosure-package__skip"
                  onClick={handleSkipClick}
                >
                  Skip this step
                </div>
              </div>
            </div>

            {uploading && (
              <div className="disclosure-package__uploading">
                <div className="disclosure-package__spinner"></div>
                <p>Uploading and analyzing your disclosure package...</p>
              </div>
            )}

            {uploadError && (
              <div className="disclosure-package__error">{uploadError}</div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default OfferNegotiationDisclosureAnalysis;
