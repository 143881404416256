const AGNSY_API_BASE_URL = process.env.REACT_APP_AGNSY_SERVER_ADDRESS;
const HTTP_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";

/**
 * Gets a file from the specified location and decodes it from base64 to ArrayBuffer.
 * @param {string} namespace - The namespace where the file is stored.
 * @param {string} path - The file path within the namespace.
 * @returns {Promise<Blob>} The file data as a Blob.
 * @throws Will throw an error if the request fails.
 */
export async function getFile(namespace: string, path: string): Promise<Blob> {
  const response = await fetch(
    `${HTTP_PROTOCAL}://${AGNSY_API_BASE_URL}/api/file/${namespace}/${encodeURIComponent(
      path
    )}`
  );
  if (!response.ok) {
    throw new Error(`Failed to fetch file: ${response.statusText}`);
  }

  const { data } = await response.json();

  // Decode the base64-encoded PDF bytes
  const bytes = atob(data);
  const byteNumbers = new Array(bytes.length);
  for (let i = 0; i < bytes.length; i++) {
    byteNumbers[i] = bytes.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray]);

  return blob;
}
