import { User, getAuth } from "firebase/auth";
import { firebaseApp } from "./firebase";

const Auth = getAuth(firebaseApp);

//This subscribes to the Auth state change and returns the unsubscribe function
async function postWithAuth(url: string, formData?: FormData) {
  const user = await getCurrentUser(Auth);

  if (!user) {
    return Promise.reject("No user is signed in");
  }

  const token = await user.getIdToken();

  const options: RequestInit = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // Only include the body if it's provided
  if (formData) {
    options.body = formData;
  }

  return fetch(url, options);
}

async function deleteWithAuth(url: string) {
  const user = await getCurrentUser(Auth);
  if (!user) {
    return new Promise<Response>((resolve, reject) => {
      reject("No user is signed in");
    });
  }
  const token = await user.getIdToken();
  return fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

async function getWithAuth(url: string) {
  const user = await getCurrentUser(Auth);
  if (!user) {
    return new Promise<Response>((resolve, reject) => {
      reject("No user is signed in");
    });
  }
  const token = await user.getIdToken();
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

function getCurrentUser(auth = Auth): Promise<User | null> {
  return new Promise<User | null>((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((user: User | null) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
}

export { Auth, postWithAuth, deleteWithAuth, getWithAuth, getCurrentUser };
