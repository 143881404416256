import React from "react";
import AISparkles from "../../../../../../assets/ai_sparkles.svg";
import CompResults from "./CompResults";

// import "../DisclosureAnalysis/AIView.css";
import { ComparableSales } from "../../../../../../model/Core/ComparableSales";

interface CompAIViewProps {
  isOpen: boolean;
  onClose: () => void;
  comparableSales: ComparableSales | null;
}

const CompAIView: React.FC<CompAIViewProps> = ({
  isOpen,
  onClose,
  comparableSales,
}) => {
  if (!isOpen || !comparableSales) return null;

  return (
    <div className="ai-view-tool">
      <div className="ai-view-tool__content">
        <div className="ai-view-tool__header">
          <div className="ai-view-tool__title">
            <img
              className="ai-view-tool__icon"
              src={AISparkles}
              alt="ai_sparkles"
            />
            <h2>Comparable Homes Analysis</h2>
          </div>
          <div>
            <button
              className="ai-view-tool__request-btn"
              onClick={() => {
                /* Implement request human review logic */
              }}
            >
              Request Human Review
            </button>
            <button className="ai-view-tool__close-btn" onClick={onClose}>
              ×
            </button>
          </div>
        </div>

        <div className="ai-view-tool__comp-body">
          <CompResults comparableSales={comparableSales} />
          {/* <img className="ai-view-tool__map-image" src={Map}/> */}
        </div>
      </div>
    </div>
  );
};

export default CompAIView;
