import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { PDFDocument, rgb } from "pdf-lib";
import { saveAs } from "file-saver";

import "./get_keys.css";
import {
  userEmailVerifiedRequirement,
  userPhoneVerifiedRequirement,
  userSignedInRequirement,
  withRequirements,
} from "../../../../requirements";

// Set the PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

function GetKeys() {
  const [pdfBytes, setPdfBytes] = useState<ArrayBuffer | null>(null);
  const [editedPdf, setEditedPdf] = useState<string | null>(null);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      setPdfBytes(reader.result as ArrayBuffer);
      setEditedPdf(null);
      setCurrentPage(1);
    };
  };

  const drawTextOnPdf = async () => {
    if (!pdfBytes) return;

    setIsLoading(true); // Start loading

    const latestPdfBytes = editedPdf
      ? await fetch(editedPdf).then((res) => res.arrayBuffer())
      : pdfBytes;

    const pdfDoc = await PDFDocument.load(latestPdfBytes, {
      ignoreEncryption: true,
    });

    const page = pdfDoc.getPages()[2]; // Select the target page

    page.drawText("1,000,000", {
      x: 280,
      y: 384,
      size: 12,
      color: rgb(0, 0, 1),
    });

    const updatedBytes = await pdfDoc.save();
    const updatedBlob = new Blob([updatedBytes], { type: "application/pdf" });
    const updatedUrl = URL.createObjectURL(updatedBlob);

    setTimeout(() => {
      setPdfBytes(updatedBytes);
      setEditedPdf(updatedUrl);
      setIsLoading(false); // Stop loading
    }, 900);
  };

  const goToNextPage = () => {
    if (numPages && currentPage < numPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="budgeting-component">
      <h2>PDF Viewer</h2>

      <input type="file" accept="application/pdf" onChange={handleFileUpload} />

      <br />

      <button onClick={drawTextOnPdf} disabled={!pdfBytes}>
        Draw Text
      </button>

      {editedPdf && (
        <button onClick={() => saveAs(editedPdf, "updated.pdf")}>
          Download Edited PDF
        </button>
      )}

      {pdfBytes && (
        <div>
          <div style={{ marginTop: "10px" }}>
            <button onClick={goToPreviousPage} disabled={currentPage <= 1}>
              Previous Page
            </button>
            <span style={{ margin: "0 10px" }}>
              Page {currentPage} of {numPages}
            </span>
            <button
              onClick={goToNextPage}
              disabled={numPages === null || currentPage >= numPages}
            >
              Next Page
            </button>
          </div>

          <div
            style={{
              minHeight: "792px",
              minWidth: "612px",
              maxHeight: "792px",
              maxWidth: "612px",
              backgroundColor: isLoading ? "#f3f3f3" : "transparent", // Grey when loading
            }}
          >
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: "792px",
                  flexDirection: "column",
                }}
              >
                <div className="spinner-pdf"></div>
                Updating PDF
              </div>
            ) : (
              <Document
                file={
                  editedPdf ||
                  URL.createObjectURL(
                    new Blob([pdfBytes], { type: "application/pdf" })
                  )
                }
                onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                loading={<div className="spinner"></div>} // PDF loading indicator
              >
                <Page
                  pageNumber={currentPage}
                  renderTextLayer={false}
                  loading={<div className="spinner"></div>}
                />
              </Document>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default withRequirements(GetKeys, [
  userSignedInRequirement,
  userEmailVerifiedRequirement,
  userPhoneVerifiedRequirement,
]);
