/* React */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

/* UI Components */

/* Functionality */

/* Requirements */

/* Styling */

import { notSignedInRequirement, withRequirements } from "../../requirements";
import {
  getLoginErrorMessage,
  LoginStatusCodes,
  UserLogin,
} from "../../../APIs/user";

import "./Login.css";
import homeIcon from "../../../assets/signup_home_icon.svg";
import agnsyLogo from "../../../assets/signup_agnsy_logo.svg";

function Login() {
  /* 
  ================================
  VARS
  ================================
  */
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  /* 
  ================================
  UI Functionality
  ================================
  */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrorMessage("");
  };

  /* 
  ================================
  SUBMISSION
  ================================
  */
  const disableSubmission =
    formData.username === "" || formData.password === "";

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage("");

    // Prepare the form data for login
    const uploadData = new FormData();
    uploadData.append("username", formData.username);
    uploadData.append("password", formData.password);

    // Attempt to sign up the user and recieve login status
    const loginStatus: LoginStatusCodes = await UserLogin(uploadData);

    // If login is successful, navigate to dashboard
    if (loginStatus === LoginStatusCodes.SUCCESS) {
      setIsLoading(false);
      navigate("/buyingflow");
    } else {
      // Retrieve an error message based on login status
      const message = getLoginErrorMessage(loginStatus);
      console.log("error message", message);
      setErrorMessage(message);
    }

    setIsLoading(false);
  };

  /* 
  ================================
  RENDERING
  ================================
  */
  return (
    <div className="login_container">
      <img src={agnsyLogo} alt="agnsy-logo" className="signup_agnsy_logo" />

      <div className="login_formWrapper">
        <img src={homeIcon} alt="home-icon" className="signup_home_icon" />

        <h2 className="login_heading">Welcome back!</h2>

        <form>
          <div className="login_inputGroup">
            <input
              type="text"
              id="username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
              placeholder="Username"
              className="login_inputField"
            />

            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
              placeholder="Password"
              className="login_inputField"
            />
          </div>

          <div>
            <p
              className={`login_alert ${
                errorMessage ? "login_alert--visible" : ""
              }`}
            >
              {errorMessage}
            </p>
          </div>

          <div>
            {isLoading ? (
              <div className="login_spinner"></div> // Show spinner
            ) : (
              <button
                className="login_button"
                onClick={handleSubmit}
                disabled={disableSubmission}
              >
                Login
              </button>
            )}
          </div>

          {/* <div className="login_forgot_link">
            <a href="/send-phone-verification-code?action=forgot-password">
              Reset Password
            </a>{" "}
            |{" "}
            <a href="/send-phone-verification-code?action=forgot-username">
              Forgot Username
            </a>
          </div> */}
        </form>

        <br />
        <div className="login_signup_link">
          Don't have an account? <a href="/signup">Sign Up</a>
        </div>
      </div>
    </div>
  );
}

export default withRequirements(Login, [notSignedInRequirement]);
