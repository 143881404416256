import React, {
  ChangeEvent,
  useRef,
  useState,
  FocusEvent,
  useEffect,
} from "react";

// Styles
import "./Landing.css";
import Kia from "../../../assets/kia_avatar.svg";
import KiaHeadshot from "../../../assets/kia_headshot.webp";
import SearchArrow from "../../../assets/landing_search_arrow.svg";
import ZillowRating from "../../../assets/landing_zillow_rating.svg";
import UnlikeTradAgentIcon from "../../../assets/unlike_trad_agent_icon.svg";
import AZPartnerIcon from "../../../assets/az_partner_icon.svg";
import HigherValueIcon from "../../../assets/higher_value_icon.svg";
import ExploreIcon from "../../../assets/landing_explore_icon.svg";
import OfferIcon from "../../../assets/landing_offer_icon.svg";
import CloseIcon from "../../../assets/landing_close_icon.svg";
import Client1 from "../../../assets/landing_client_1.webp";
import Client4 from "../../../assets/landing_client_4.webp";
import Client5 from "../../../assets/landing_client_5.webp";
import agnsyLogo from "../../../assets/landing_agnsy_logo.svg";

// TODO: Update happy clients to be a spiral

// TODO: Connect email pop up (or re-add it my bad lol).
// git has that had it was e3ed73a [added compatibility ...]

// TODO: Connect search engine

// TODO: fix exclusive listing style.
// retrieve listings from backend rather than hardcode (maybe??)

// TODO: give functionality for footer

// TODO: Give button "Book a call" functionality

// TODO: Give button "Buy with us" functionality

function Landing() {
  /* 
  ================================================================
  CONSTANTS
  ================================================================
  */

  // Search engine constants
  const fixedPrefix = "Describe me your dream home: ";
  const animatedPart = "3 bed home in Cupertino";
  const typingSpeed = 100; // milliseconds per character
  const deletingSpeed = 50; // milliseconds per character
  const pauseTime = 2000; // pause time when text is fully typed or deleted

  // Rebate constants
  const minPrice = 800000;
  const maxPrice = 6000000;
  const commissionRatio = 47.0588; // Home price divided by commission

  /* 
  ================================================================
  STATE VARIABLES
  ================================================================
  */

  const [inputValue, setInputValue] = useState<string>(
    "Describe me your dream home: "
  );
  const [isFocused, setIsFocused] = useState<boolean>(false);

  // Price calculator state
  const [homePrice, setHomePrice] = useState<number>(2000000);
  const [commission, setCommission] = useState<number>(0);

  // Home search
  const [slideIndex, setSlideIndex] = useState<number>(0);

  /* 
  ================================================================
  REFERENCE VARIABLES
  ================================================================
  */
  // Listing refs
  const listingsTrackRef = useRef<HTMLDivElement>(null);

  // Rebate ref
  const inputRef = useRef<HTMLInputElement>(null);

  /* 
  ================================================================
  EVENT HANDLERS
  ================================================================
  */
  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setInputValue(e.target.value);
  };

  const handleFocus = (): void => {
    setIsFocused(true);
    setInputValue(""); // Clear the input when user focuses
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>): void => {
    // Only unfocus if the input is empty
    if (e.target.value === "") {
      setIsFocused(false);
      setInputValue(fixedPrefix); // Reset to just the prefix
    }
  };

  const handlePrevSlide = () => {
    if (slideIndex > 0) {
      setSlideIndex(slideIndex - 1);
    } else {
      // Wrap around to the last slide
      setSlideIndex(1);
    }
  };

  const handleNextSlide = () => {
    if (slideIndex < 1) {
      setSlideIndex(slideIndex + 1);
    } else {
      // Wrap around to the first slide
      setSlideIndex(0);
    }
  };

  /* 
  ================================================================
  HOOKS
  ================================================================
  */
  useEffect(() => {
    if (isFocused) return; // Don't animate if the user is focused on the input

    let timeout: NodeJS.Timeout;
    let isTyping = true;
    let currentIndex = 0;

    const animate = () => {
      if (isTyping) {
        // Typing animation - only animate the variable part
        if (currentIndex <= animatedPart.length) {
          setInputValue(fixedPrefix + animatedPart.substring(0, currentIndex));
          currentIndex++;
          timeout = setTimeout(animate, typingSpeed);
        } else {
          // Pause at the end of typing
          isTyping = false;
          timeout = setTimeout(animate, pauseTime);
        }
      } else {
        // Deleting animation - only delete the variable part
        if (currentIndex > 0) {
          currentIndex--;
          setInputValue(fixedPrefix + animatedPart.substring(0, currentIndex));
          timeout = setTimeout(animate, deletingSpeed);
        } else {
          // Pause before starting to type again
          isTyping = true;
          timeout = setTimeout(animate, pauseTime);
        }
      }
    };

    timeout = setTimeout(animate, pauseTime); // Start the animation

    return () => clearTimeout(timeout); // Clean up the timeout
  }, [isFocused]);

  // Calculate commission when home price changes
  useEffect(() => {
    setCommission(Math.round((homePrice / commissionRatio) * 100) / 100);
  }, [homePrice]);

  // Handle slider change
  const handleSliderChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setHomePrice(Number(e.target.value));
  };

  /* 
  ================================================================
  HELPER FUNCTIONS
  ================================================================
  */
  // Format currency values
  const formatPrice = (price: number): string => {
    return price.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  const formatCommission = (price: number): string => {
    return price.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  // Calculate slider position percentage
  const calculateFillPercentage = (): number => {
    return ((homePrice - minPrice) / (maxPrice - minPrice)) * 100;
  };

  /* 
  ================================================================
  RENDER
  ================================================================
  */
  return (
    <div className="landing">
      {/* Hero Section */}
      <section className="landing-hero">
        <div className="landing-hero__content">
          <h2 className="landing-hero__subheadline">
            <strong>Exclusively</strong> in the{" "}
            <strong>San Francisco Bay Area</strong>
          </h2>

          <h1 className="landing-hero__headline">
            Buy a Home, Get <span>$51,000*</span>
          </h1>
          <p className="landing-hero__description">
            With our AI real estate platform. A new age, better outcomes.
          </p>

          <div className="landing-hero__search">
            <div className="landing-hero__search-icon-container">
              <img className="landing-hero__search-icon" src={Kia} alt="Kia" />
            </div>

            <input
              ref={inputRef}
              type="text"
              className="landing-hero__search-input"
              placeholder=""
              value={inputValue}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />

            <button className="landing__search-button">
              <img
                src={SearchArrow}
                alt="Search Icon"
                className="landing__search-icon"
              />
            </button>
          </div>

          <div className="landing-hero__zillow-rating-container">
            <a
              href="https://www.zillow.com/profile/agnsy"
              target="_blank"
              rel="noopener noreferrer"
              // onClick={(e) => {
              //   e.preventDefault();
              //   window.open(
              //     "https://www.zillow.com/profile/agnsy",
              //     "ZillowProfile",
              //     "width=600,height=400,scrollbars=yes,resizable=yes"
              //   );
              // }}
            >
              <img
                src={ZillowRating}
                alt="Zillow 5 Starts"
                className="landing-hero__zillow-rating"
              />
            </a>
          </div>

          <p className="landing-hero__disclaimer">
            *Based on 2024 Santa Clara County average price of $2.4M and a 2.5%
            buyers agent commission fee. Terms apply.
          </p>

          <div className="landing-hero__reviews">
            <div className="landing-hero__reviews-track">
              <p className="landing-hero__review">
                Wei got a $31,250 rebate in San Jose
              </p>
              <p className="landing-hero__review">
                Gaurav got a $61,000 rebate in Milpitas
              </p>
              <p className="landing-hero__review">
                Michelle got a $102,000 rebate in Los Altos
              </p>
              <p className="landing-hero__review">
                Reza got a $41,450 rebate in Pleasanton
              </p>
              <p className="landing-hero__review">
                Itay got a $66,300 rebate in Sunnyvale
              </p>
              {/* Duplication */}
              <p className="landing-hero__review">
                Wei got a $31,250 rebate in San Jose
              </p>
              <p className="landing-hero__review">
                Gaurav got a $61,000 rebate in Milpitas
              </p>
              <p className="landing-hero__review">
                Michelle got a $102,000 rebate in Los Altos
              </p>
              <p className="landing-hero__review">
                Reza got a $41,450 rebate in Pleasanton
              </p>
              <p className="landing-hero__review">
                Itay got a $66,300 rebate in Sunnyvale
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Value Card Section*/}
      <section className="landing-value-cards">
        <div className="landing-value-cards__container">
          <div className="landing-value-card landing-value-card--unlike-trade-agent">
            <div className="landing-value-card__icon-container">
              <img
                src={UnlikeTradAgentIcon}
                className="landing-value-card__icon"
                alt="Traditional Agents"
              />
            </div>
            <h3 className="landing-value-card__title">
              Unlike Traditional Agents
            </h3>
            <p className="landing-value-card__description">
              Who have misaligned interests, take hours to respond, and are
              overly compensated for filling in a standardized contract.
            </p>
          </div>

          <div className="landing-value-card landing-value-card--az-partner">
            <div className="landing-value-card__icon-container">
              <img
                src={AZPartnerIcon}
                className="landing-value-card__icon"
                alt="A-Z Partner"
              />
            </div>
            <h3 className="landing-value-card__title">Your A-Z Partner</h3>
            <p className="landing-value-card__description">
              Access to off-market homes, Instant and comprehensive disclosure
              analyses, valuations, negotiation planning, and guided
              offer-writing.
            </p>
          </div>

          <div className="landing-value-card landing-value-card--higher-value">
            <div className="landing-value-card__icon-container">
              <img
                src={HigherValueIcon}
                className="landing-value-card__icon"
                alt="Higher Value"
              />
            </div>
            <h3 className="landing-value-card__title">Higher Value</h3>
            <p className="landing-value-card__description">
              Smarter, faster, and a higher value option! Get a rebate of 85%
              from the buy-side commission at closing.
            </p>
          </div>
        </div>
      </section>

      {/* Rebate section */}
      <section className="landing-rebate">
        <h2 className="landing-rebate__title">
          The AI agent that pays you when you buy
        </h2>

        <div className="landing-rebate__container">
          <div className="landing-rebate__card">
            <div className="landing-rebate__content">
              <div className="landing-rebate__avatar-container">
                <img
                  src={Kia}
                  alt="Kia AI"
                  className="landing-rebate__avatar"
                />
              </div>

              <div className="landing-rebate__details">
                <div className="landing-rebate__row">
                  <p className="landing-rebate__label">Home Sale Price</p>
                  <p className="landing-rebate__value">
                    {formatPrice(homePrice)}
                  </p>
                </div>

                <input
                  type="range"
                  min={minPrice}
                  max={maxPrice}
                  value={homePrice}
                  onChange={handleSliderChange}
                  className="landing-rebate__slider"
                  style={
                    {
                      "--slider-percent": `${calculateFillPercentage()}%`,
                    } as React.CSSProperties
                  }
                />

                <div className="landing-rebate__row">
                  <p className="landing-rebate__label landing-rebate__label--agnsy-rebate">
                    Agnsy Rebate:
                  </p>
                  <p className="landing-rebate__value landing-rebate__value--agnsy-rebate">
                    {formatCommission(commission)}
                  </p>
                </div>
              </div>
            </div>

            <p className="landing-rebate__access">
              You now have access to a{" "}
              <span>{formatPrice(homePrice + commission)}</span> home
            </p>
          </div>

          <div className="landing-rebate__info">
            <p className="landing-rebate__info-description">
              We forward a majority of the buyer’s agent commission earnings to
              you, when you buy with{" "}
              <span className="landing-rebate__highlight-text">Agnsy</span>.
            </p>
            <button className="landing-rebate__button">Book a call</button>
          </div>
        </div>
      </section>

      {/* Step Sections */}
      <section className="landing-steps">
        <h2 className="landing-steps__title">
          Home is <span className="landing-steps__highlight">three</span> steps
          away
        </h2>

        <div className="landing-steps__container">
          <div className="landing-step">
            <div className="landing-step__icon-container">
              <img
                src={ExploreIcon}
                className="landing-step__icon landing-step__icon--explore"
                alt="Explore"
              />
            </div>
            <h3 className="landing-step__number">1. Explore</h3>
            <p className="landing-step__description">
              Our{" "}
              <span className="landing-step__description-highlight">AI</span>{" "}
              matches you with a lender, shares tailored listings, and schedules
              tours.
            </p>
          </div>

          <div className="landing-step">
            <div className="landing-step__icon-container">
              <img
                src={OfferIcon}
                className="landing-step__icon landing-step__icon--offer"
                alt="Offer"
              />
            </div>
            <h3 className="landing-step__number">2. Offer</h3>
            <p className="landing-step__description">
              We leverage{" "}
              <span className="landing-step__description-highlight">
                real-time market data
              </span>{" "}
              for precise valuations,
              <span className="landing-step__description-highlight">
                thoroughly review
              </span>
              inspection reports and disclosures,{" "}
              <span className="landing-step__description-highlight">
                strategically negotiate{" "}
              </span>{" "}
              the lowest possible price, and{" "}
              <span className="landing-step__description-highlight">
                expertly{" "}
              </span>
              handle all offers including counters.
            </p>
          </div>

          <div className="landing-step">
            <div className="landing-step__icon-container">
              <img
                src={CloseIcon}
                className="landing-step__icon landing-step__icon--close"
                alt="Close"
              />
            </div>
            <h3 className="landing-step__number">3. Close</h3>
            <p className="landing-step__description">
              We coordinate with the lender, escrow, and sellers to keep the
              transaction on track. And upon closing,{" "}
              <span className="landing-step__description-highlight">
                you're credited <strong>85%</strong>{" "}
              </span>
              of the buy-side commission!
            </p>
          </div>
        </div>

        <p className="landing-steps__explanation">
          An end-to-end guided system curated with over <strong>$5B</strong>{" "}
          worth of transaction expertise — ultimately leading to superior
          homebuying outcomes!
        </p>
      </section>

      {/* Client Sections */}
      <section className="landing-clients">
        <h2 className="landing-clients__title">Happy Clients</h2>

        <div className="landing-clients__container">
          <div className="landing-client-card">
            <img
              src={Client1}
              alt="Client"
              className="landing-client-card__image"
            />
            <div className="landing-client-card__details">
              <p className="landing-client-card__price">$1,480,000</p>
              <p className="landing-client-card__location">San Jose, CA</p>
              <p className="landing-client-card__rebate">
                Agnsy Rebate: <strong> $31K</strong>
              </p>
            </div>
          </div>

          <div className="landing-client-card">
            <img
              src={Client4}
              alt="Client"
              className="landing-client-card__image"
            />
            <div className="landing-client-card__details">
              <p className="landing-client-card__price">$2,100,000</p>
              <p className="landing-client-card__location">Palo Alto, CA</p>
              <p className="landing-client-card__rebate">
                Agnsy Rebate: <strong> $45K</strong>
              </p>
            </div>
          </div>
          <div className="landing-client-card">
            <img
              src={Client5}
              alt="Client"
              className="landing-client-card__image"
            />
            <div className="landing-client-card__details">
              <p className="landing-client-card__price">$2,100,000</p>
              <p className="landing-client-card__location">Palo Alto, CA</p>
              <p className="landing-client-card__rebate">
                Agnsy Rebate: <strong> $45K</strong>
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Founder Section */}
      <section className="landing-founder">
        <h2 className="landing-founder__title">Our Lead Agent & Co-founder</h2>

        <div className="landing-founder__content">
          <div className="landing-founder__text">
            <h3 className="landing-founder__name">Kia Kalhori</h3>
            <p className="landing-founder__description">
              Kia started his real estate career at 18, closing $21.6M in
              Silicon Valley homes within his first year. An unheard-of feat for
              a teenage rookie in one of the nation's toughest markets.
            </p>
            <p className="landing-founder__description">
              He's negotiated millions in savings for buyers, including a $620K
              discount on a $5M Los Altos Hills estate against a Wall Street
              Journal-ranked listing team.
            </p>
            <p className="landing-founder__description">
              With experience selling homes during the market's most competitive
              years when rates were under 3%, he knows exactly how to structure
              offers for acceptance while keeping his buyers ahead.
            </p>
            <p className="landing-founder__description">
              Having grown up in Section 8 housing, he understands the value of
              homeownership. In 2023, alongside UCLA engineers from Meta, NASA,
              and VMware, he set out to make buying a home seamless,
              transparent, and as accessible as possible.
            </p>
          </div>

          <div className="landing-founder__image-container">
            <img
              src={KiaHeadshot}
              alt="Kia Kalhori"
              className="landing-founder__image"
            />
          </div>
        </div>

        <button className="landing-founder__button">Buy With Us</button>
      </section>

      {/* Exclusive Listings */}
      <section className="landing-exclusive-listings">
        <div className="exclusive-listings">
          <div className="exclusive-listings__header">
            <div className="exclusive-listings__title-container">
              <h2 className="exclusive-listings__title">Exclusive Listings</h2>
              <p className="exclusive-listings__subtitle">
                Browse exclusive listings before they hit the market.
              </p>
            </div>
            <div className="exclusive-listings__controls">
              <button
                className="exclusive-listings__nav exclusive-listings__nav--prev"
                onClick={handlePrevSlide}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 18L9 12L15 6"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
              <button
                className="exclusive-listings__nav exclusive-listings__nav--next"
                onClick={handleNextSlide}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 6L15 12L9 18"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>

          <div className="exclusive-listings__carousel">
            <div
              className="exclusive-listings__track"
              ref={listingsTrackRef}
              style={{ transform: `translateX(-${slideIndex * 100}%)` }}
            >
              {/* First set of listings */}
              <div className="exclusive-listings__page">
                <div className="exclusive-listing-card">
                  <div className="exclusive-listing-card__image-container">
                    <img
                      src={require("../../../assets/landing_home_1.webp")}
                      alt="Home in Cupertino"
                      className="exclusive-listing-card__image"
                    />
                    <div className="exclusive-listing-card__tag">
                      Agent Rebate: $28k
                    </div>
                  </div>
                  <div className="exclusive-listing-card__price">
                    $3,098,000
                  </div>
                  <div className="exclusive-listing-card__details">
                    <span>3 beds</span> • <span>3 baths</span> •{" "}
                    <span>2,740 sqft</span>
                  </div>
                  <div className="exclusive-listing-card__address">
                    123 Bubb Rd, Cupertino, CA 95014
                  </div>
                </div>

                <div className="exclusive-listing-card">
                  <div className="exclusive-listing-card__image-container">
                    <img
                      src={require("../../../assets/landing_home_2.webp")}
                      alt="Home in Cupertino"
                      className="exclusive-listing-card__image"
                    />
                    <div className="exclusive-listing-card__tag">
                      Agent Rebate: $28k
                    </div>
                  </div>
                  <div className="exclusive-listing-card__price">
                    $3,098,000
                  </div>
                  <div className="exclusive-listing-card__details">
                    <span>3 beds</span> • <span>3 baths</span> •{" "}
                    <span>2,740 sqft</span>
                  </div>
                  <div className="exclusive-listing-card__address">
                    123 Bubb Rd, Cupertino, CA 95014
                  </div>
                </div>

                <div className="exclusive-listing-card">
                  <div className="exclusive-listing-card__image-container">
                    <img
                      src={require("../../../assets/landing_home_2.webp")}
                      alt="Home in Cupertino"
                      className="exclusive-listing-card__image"
                    />
                    <div className="exclusive-listing-card__tag">
                      Agent Rebate: $28k
                    </div>
                  </div>
                  <div className="exclusive-listing-card__price">
                    $3,098,000
                  </div>
                  <div className="exclusive-listing-card__details">
                    <span>3 beds</span> • <span>3 baths</span> •{" "}
                    <span>2,740 sqft</span>
                  </div>
                  <div className="exclusive-listing-card__address">
                    123 Bubb Rd, Cupertino, CA 95014
                  </div>
                </div>

                <div className="exclusive-listing-card">
                  <div className="exclusive-listing-card__image-container">
                    <img
                      src={require("../../../assets/landing_home_2.webp")}
                      alt="Home in Cupertino"
                      className="exclusive-listing-card__image"
                    />
                    <div className="exclusive-listing-card__tag">
                      Agent Rebate: $28k
                    </div>
                  </div>
                  <div className="exclusive-listing-card__price">
                    $3,098,000
                  </div>
                  <div className="exclusive-listing-card__details">
                    <span>3 beds</span> • <span>3 baths</span> •{" "}
                    <span>2,740 sqft</span>
                  </div>
                  <div className="exclusive-listing-card__address">
                    123 Bubb Rd, Cupertino, CA 95014
                  </div>
                </div>
              </div>

              {/* Second set of listings */}
              <div className="exclusive-listings__page">
                <div className="exclusive-listing-card">
                  <div className="exclusive-listing-card__image-container">
                    <img
                      src={require("../../../assets/landing_home_4.webp")}
                      alt="Home in Cupertino"
                      className="exclusive-listing-card__image"
                    />
                    <div className="exclusive-listing-card__tag">
                      Agent Rebate: $30k
                    </div>
                  </div>
                  <div className="exclusive-listing-card__price">
                    $3,295,000
                  </div>
                  <div className="exclusive-listing-card__details">
                    <span>4 beds</span> • <span>3 baths</span> •{" "}
                    <span>2,950 sqft</span>
                  </div>
                  <div className="exclusive-listing-card__address">
                    456 Foothill Blvd, Cupertino, CA 95014
                  </div>
                </div>

                <div className="exclusive-listing-card">
                  <div className="exclusive-listing-card__image-container">
                    <img
                      src={require("../../../assets/landing_home_4.webp")}
                      alt="Home in Cupertino"
                      className="exclusive-listing-card__image"
                    />
                    <div className="exclusive-listing-card__tag">
                      Agent Rebate: $27k
                    </div>
                  </div>
                  <div className="exclusive-listing-card__price">
                    $2,980,000
                  </div>
                  <div className="exclusive-listing-card__details">
                    <span>3 beds</span> • <span>2 baths</span> •{" "}
                    <span>2,560 sqft</span>
                  </div>
                  <div className="exclusive-listing-card__address">
                    789 Stevens Creek Blvd, Cupertino, CA 95014
                  </div>
                </div>
                <div className="exclusive-listing-card">
                  <div className="exclusive-listing-card__image-container">
                    <img
                      src={require("../../../assets/landing_home_4.webp")}
                      alt="Home in Cupertino"
                      className="exclusive-listing-card__image"
                    />
                    <div className="exclusive-listing-card__tag">
                      Agent Rebate: $31k
                    </div>
                  </div>
                  <div className="exclusive-listing-card__price">
                    $3,450,000
                  </div>
                  <div className="exclusive-listing-card__details">
                    <span>4 beds</span> • <span>3.5 baths</span> •{" "}
                    <span>3,100 sqft</span>
                  </div>
                  <div className="exclusive-listing-card__address">
                    321 De Anza Blvd, Cupertino, CA 95014
                  </div>
                </div>

                <div className="exclusive-listing-card">
                  <div className="exclusive-listing-card__image-container">
                    <img
                      src={require("../../../assets/landing_home_4.webp")}
                      alt="Home in Cupertino"
                      className="exclusive-listing-card__image"
                    />
                    <div className="exclusive-listing-card__tag">
                      Agent Rebate: $25k
                    </div>
                  </div>
                  <div className="exclusive-listing-card__price">
                    $2,750,000
                  </div>
                  <div className="exclusive-listing-card__details">
                    <span>3 beds</span> • <span>2 baths</span> •{" "}
                    <span>2,200 sqft</span>
                  </div>
                  <div className="exclusive-listing-card__address">
                    555 Homestead Rd, Cupertino, CA 95014
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <section className="footer-section">
        <footer className="footer">
          <div className="footer__container">
            <div className="footer__left">
              <img src={agnsyLogo} alt="Agnsy Logo" className="footer__logo" />
              <p className="footer__tagline">Better homebuying outcomes</p>
              <button className="footer__button">Find Home</button>
            </div>

            <div className="footer__nav">
              <div className="footer__nav-column">
                <h3 className="footer__nav-title">Services</h3>
                <ul className="footer__nav-list">
                  <li className="footer__nav-list-item">
                    <a href="/" className="footer__nav-link">
                      Buy
                    </a>
                  </li>
                  <li className="footer__nav-list-item">
                    <a href="/" className="footer__nav-link">
                      Sell
                    </a>
                  </li>
                </ul>
              </div>

              <div className="footer__nav-column">
                <h3 className="footer__nav-title">Resources</h3>
                <ul className="footer__nav-list">
                  <li className="footer__nav-list-item">
                    <a href="/" className="footer__nav-link">
                      Agnsy Guide
                    </a>
                  </li>
                  <li className="footer__nav-list-item">
                    <a href="/" className="footer__nav-link">
                      Blog
                    </a>
                  </li>
                  <li className="footer__nav-list-item">
                    <a href="/" className="footer__nav-link">
                      Neighborhood Guide
                    </a>
                  </li>
                </ul>
              </div>

              <div className="footer__nav-column">
                <h3 className="footer__nav-title">Company</h3>
                <ul className="footer__nav-list">
                  <li className="footer__nav-list-item">
                    <a href="/" className="footer__nav-link">
                      Contact
                    </a>
                  </li>
                  <li className="footer__nav-list-item">
                    <a href="/" className="footer__nav-link">
                      Investors
                    </a>
                  </li>

                  <li className="footer__nav-list-item">
                    <a href="/" className="footer__nav-link">
                      Terms of Use
                    </a>
                  </li>
                  <li className="footer__nav-list-item">
                    <a href="/" className="footer__nav-link">
                      Privacy Policy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer__social">
              <a href="/" className="footer__social-link">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="white"
                >
                  <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                </svg>
              </a>
              <a href="/" className="footer__social-link">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="white"
                >
                  <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
                </svg>
              </a>
              <a href="/" className="footer__social-link">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="white"
                >
                  <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z" />
                </svg>
              </a>
            </div>
          </div>

          <div className="footer__notice">
            Agnsy Inc © 2025 - All Rights Reserved
          </div>
          <div className="footer__notice footer__notice--DRE">
            Lyst Residential DRE #01333807 | Kia Kalhori DRE #02114300
          </div>
        </footer>
      </section>
    </div>
  );
}

export default Landing;
