/* React */
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

/* Data Models */

/* UI Components */
import PhoneInput from "react-phone-number-input";

/* Functionality */
import { parsePhoneNumberFromString } from "libphonenumber-js";

/* Styling */
import { UserPhone } from "../../../APIs/user";
import { postWithAuth } from "../../../firebase/authentication";

import "./SendPhoneVerificationCode.css";

const AGNSY_API_BASE_URL = process.env.REACT_APP_AGNSY_SERVER_ADDRESS;
const HTTP_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";

function SendPhoneVerificationCode() {
  const [searchParams] = useSearchParams();
  const action = searchParams.get("action");

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [disableSubmission, setDisableSubmission] = useState<boolean>(true);
  const [phoneData, setPhoneData] = useState<UserPhone | null>(null);
  const navigate = useNavigate();

  const actionTitles: { [key: string]: string } = {
    "forgot-password": "Forgot Password",
    "forgot-username": "Forgot Username",
    "account-verify": "Verify Account",
  };

  const title = action ? actionTitles[action] ?? "" : "";

  useEffect(() => {
    if (!action) {
      navigate("/");
    }
  }, [action, navigate]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    const uploadData = new FormData();
    uploadData.append("phone", phoneNumber);

    setPhoneData({ ...phoneData, number: phoneNumber } as UserPhone);
    setIsLoading(false);
  };

  const validatePhoneNumber = (phoneNumber: string) => {
    const phone = parsePhoneNumberFromString(phoneNumber);
    if (!phone || !phone.isValid()) {
      setDisableSubmission(true);
      return "Invalid phone number";
    }
    setDisableSubmission(false);
    return "";
  };

  const validateForm = (value: string) => {
    let error = "";

    error = validatePhoneNumber(value);
    setErrorMessage(error);
  };

  const sendPhoneCode = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage("");

    if (!phoneData) {
      return;
    }

    try {
      let message = "";
      switch (action) {
        case "account-verify":
          message = "ok";
          break;
        case "forgot-password":
          message = await sendForgotPasswordCode(phoneNumber);
          break;
        case "forgot-username":
          message = await sendForgotUsernameCode(phoneNumber);
          break;
        default:
          message = "Error. Please try again.";
      }

      if (message === "ok") {
        setIsLoading(false);
        navigate(
          `/verify-phone?phone=${encodeURIComponent(
            phoneNumber
          )}&action=${action}`
        );
      }
      setErrorMessage(message);
    } catch (error) {
      console.error("Error sending verification code:", error);
    }

    setIsLoading(false);
  };

  return (
    <div className="send_verification_code_container">
      {phoneData ? (
        <div className="send_verification_code_formWrapper">
          <form>
            <h2 className="send_verification_code_heading">
              Access to certain content requires phone verification
            </h2>

            <div className="send_verification_code_subtitle">
              A verification code will be sent to:
              <div>{phoneData.number}</div>
            </div>

            <div className="send_verification_code_alert">
              {errorMessage && <p>{errorMessage}</p>}
            </div>

            <div className="send_verification_code_inputGroup">
              {isLoading ? (
                <div className="send_verification_code_spinner"></div>
              ) : (
                <button
                  className="send_verification_code_button"
                  onClick={sendPhoneCode}
                >
                  Send code
                </button>
              )}
            </div>
          </form>
        </div>
      ) : (
        <div className="send_verification_code_formWrapper">
          <h2 className="send_verification_code_heading">{title}</h2>

          <form onSubmit={handleSubmit}>
            <div className="send_verification_code_inputLabel">
              Enter your phone number
            </div>

            <div className="send_verification_code_inputGroup">
              <PhoneInput
                international
                value={phoneNumber}
                defaultCountry="US"
                onChange={(value: string | undefined) => {
                  setPhoneNumber(value || "");
                  validateForm(value || "");
                }}
                required
                placeholder="Phone Number"
                className="send_verification_code_inputField"
              />
            </div>

            <div className="send_verification_code_alert">
              {errorMessage && <p>{errorMessage}</p>}
            </div>

            <div className="send_verification_code_inputGroup">
              {isLoading ? (
                <div className="send_verification_code_spinner"></div>
              ) : (
                <button
                  type="submit"
                  disabled={disableSubmission}
                  className="send_verification_code_button"
                >
                  Continue
                </button>
              )}
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export async function sendVerifyAccountCode(
  phoneNumber: string
): Promise<string> {
  try {
    const formData = new FormData();
    formData.append("phone", phoneNumber);

    const response = await postWithAuth(
      `${HTTP_PROTOCAL}://${AGNSY_API_BASE_URL}/api/user/auth/phone/send-verification-code`,
      formData
    );

    if (response.ok) {
      console.log("verified account");
      return "ok";
    } else {
      switch (response.status) {
        case 401:
          return "Token for user is invalid or expired";
        case 404:
          return "User doesn't exist";
        case 409:
          return "Phone number already verified with another account";
        case 500:
          return "Failed to send username retrieval code";
        default:
          return `Unexpected error occurred: ${response.status}`;
      }
    }
  } catch (error) {
    return `Error sending verification code: ${(error as Error).message}`;
  }
}

export async function sendForgotUsernameCode(
  phoneNumber: string
): Promise<string> {
  try {
    const formData = new FormData();
    formData.append("phone", phoneNumber);

    const options: RequestInit = {
      method: "POST",
      body: formData,
    };

    const response = await fetch(
      `${HTTP_PROTOCAL}://${AGNSY_API_BASE_URL}/api/user/auth/username/forgot-username-request`,
      options
    );

    if (response.ok) {
      return "ok";
    } else {
      switch (response.status) {
        case 404:
          return "No verified users associated with the provided phone number";
        case 500:
          return "Server error. Try again later";
        default:
          return "Failed to send username retrieval code";
      }
    }
  } catch (error) {
    return `Error sending username retrieval code`;
  }
}

export async function sendForgotPasswordCode(
  phoneNumber: string
): Promise<string> {
  try {
    const formData = new FormData();
    formData.append("phone", phoneNumber);

    const options: RequestInit = {
      method: "POST",
      body: formData,
    };

    const response = await fetch(
      `${HTTP_PROTOCAL}://${AGNSY_API_BASE_URL}/api/user/auth/password/password-reset-request`,
      options
    );

    if (response.ok) {
      console.log("reset password request");
      return "ok";
    } else {
      switch (response.status) {
        case 404:
          return "No verified users associated with the provided phone number";
        case 500:
          return "Server error. Try again later";
        default:
          return "Failed to send username retrieval code";
      }
    }
  } catch (error) {
    return `Error sending username retrieval code`;
  }
}

export default SendPhoneVerificationCode;
