import "./lender.css";
import { useEffect, useState } from "react";
import { getLenderAgentRecommendations } from "../../../../../../APIs/budgeting";
import { useNavigateWithFlowIdURLParam } from "../../../BuyingFlow";
import { LenderAgentRecommendationResponse } from "../../../../../../model/Core/BuyingFlow/Budgeting";
import { TOOLS, useToolContext } from "../../../Tools/ToolViewerContext";

// Budgeting Finding a Lender Section
const BudgetingLender: React.FC = () => {
  const navigateWithFlow = useNavigateWithFlowIdURLParam();
  const { openTool } = useToolContext();

  const [lenders, setLenders] = useState<LenderAgentRecommendationResponse[]>(
    []
  );

  useEffect(() => {
    async function updateLenders() {
      const response = await getLenderAgentRecommendations();
      setLenders(response);
    }

    updateLenders();
  }, []);

  // Navigation
  const handlePreApprovalClick = () => {
    navigateWithFlow("/buyingflow/budgeting/pre-approval");
  };

  const handleEnterCashClick = () => {
    navigateWithFlow("/buyingflow/budgeting/enter-cash");
  };

  return (
    <div className="lender">
      {/* Introduction Section */}
      <section className="lender__intro">
        <p className="lender__description">
          Below are some lending companies that can help get you pre-approved.
        </p>
        <p className="lender__description">
          Contacting multiple lenders at the same time is a good idea if you
          want to try to get the best loan terms.
        </p>
      </section>

      {/* Tips Section */}
      <section className="lender__tips">
        <h3 className="lender__tips-title">Agnsy Tips:</h3>
        <ul className="lender__tips-list">
          <li className="lender__tips-item">
            Pre-approvals typically need renewing every 90-days
          </li>
          <li className="lender__tips-item">
            If applying to multiple lenders, best to apply simultaneously to
            avoid minor credit hits over time
          </li>
          <li className="lender__tips-item">
            You are not married to your rate. You have the option to refinance
            180 days after home purchase, assuming equity build
          </li>
        </ul>
      </section>

      {/* Chat Recommendations */}
      <section className="lender__chat-suggestions">
        <button className="lender__chat-suggestion">
          What are home loan terms?
        </button>
        <button className="lender__chat-suggestion">
          What do I need to look out for?
        </button>
      </section>

      {/* Shortcuts */}
      <section className="lender__shortcuts">
        <div className="lender__shortcut-link" onClick={handlePreApprovalClick}>
          Already have a lender and pre-approval letter? Click here to continue.
        </div>
        <div className="lender__shortcut-link" onClick={handleEnterCashClick}>
          Buying in all cash? Click here to continue.
        </div>
      </section>

      {/* Lenders Table */}
      <section className="lender__table-container">
        <table className="lender__table">
          <thead>
            <tr>
              <th className="lender__table-header">Lender</th>
              <th className="lender__table-header">APR</th>
              <th className="lender__table-header">Loan Agent</th>
              <th className="lender__table-header">Get started</th>
            </tr>
          </thead>

          <tbody>
            {lenders.map((lender, index) => (
              <tr key={index} className="lender__row">
                <td className="lender__cell lender__cell--logo">
                  <img
                    className="lender__logo"
                    src={`data:image/jpeg;base64,${lender.company_photo}`}
                    alt="Company Photos"
                    loading="eager"
                  />
                </td>
                <td className="lender__cell lender__cell--apr">
                  {lender.apr_rate}%
                </td>
                <td className="lender__cell lender__cell--agent">
                  <div className="lender__agent">
                    <div className="lender__agent-photo-wrapper">
                      <img
                        className="lender__agent-photo"
                        src={`data:image/jpeg;base64,${lender.photo}`}
                        alt={lender.name}
                      />
                    </div>
                    <div className="lender__agent-details">
                      <div className="lender__agent-name">{lender.name}</div>
                      <div className="lender__agent-email">{lender.email}</div>
                      <div className="lender__agent-phone">{lender.phone}</div>
                    </div>
                  </div>
                </td>
                <td className="lender__cell lender__cell--action">
                  <button
                    className="lender__action-button"
                    onClick={() => openTool(TOOLS.AI_EMAIL)}
                  >
                    Send Email
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>

      {/* Call to Action */}
      <section className="lender__cta">
        <button className="lender__cta-button" onClick={handlePreApprovalClick}>
          Got a Pre-Approval Letter!
        </button>
      </section>

      {/* Cash Option */}
      <section className="lender__cash-option">
        <div className="lender__shortcut-link" onClick={handleEnterCashClick}>
          Buying in all cash? Click here to continue.
        </div>
      </section>
    </div>
  );
};

export default BudgetingLender;
