import React, { useEffect, useState } from "react";
import Chat from "../../../../../../assets/chat.svg";
import "./introduction.css";
import { useNavigateWithFlowIdURLParam } from "../../../BuyingFlow";
import { Home } from "../../../../../../model/Core/Home";
import { User } from "../../../../../../model/Core/User";
import {
  fetchCurrentUserData,
  fetchHomeData,
} from "../../../../../../APIs/user";
import { BuyingFlow } from "../../../../../../model/Core/BuyingFlow";
import { useSearchParams } from "react-router-dom";
import { TOOLS, useToolContext } from "../../../Tools/ToolViewerContext";

// Budgeting Introduction step
const BudgetingIntroduction: React.FC = () => {
  /* 
  ================================================================
  CONSTANTS
  ================================================================
  */
  const navigateWithFlow = useNavigateWithFlowIdURLParam();
  const [searchParams] = useSearchParams();
  const flowId = searchParams.get("flow")?.replace(/^"|"$/g, "");

  /* 
  ================================================================
  STATE VARIABLES
  ================================================================
  */
  const [currentHome, setCurrentHome] = useState<Home | null>(null);
  const { openTool } = useToolContext();

  /* 
  ================================================================
  HOOK: INITIAL
  ================================================================
  */
  useEffect(() => {
    const fetchData = async () => {
      let userData: User | null = await fetchCurrentUserData();
      let buyingFlowData: BuyingFlow | null = null;

      if (flowId && userData !== null) {
        buyingFlowData =
          userData.buying_flows.find((flow) => flow.id === flowId) || null;
        if (buyingFlowData !== null) {
          let homeData: Home | null = await fetchHomeData(
            buyingFlowData.home_id
          );
          setCurrentHome(homeData);
          return;
        }
      }

      navigateWithFlow("/buyingflow/flow-nonexistent");
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* 
  ================================================================
  FUNC: Next step navigation 
  ================================================================
  */
  const handleClick = () => {
    navigateWithFlow(`/buyingflow/budgeting/lender`);
  };

  /* 
  ================================================================
  RENDER: DISPLAY LOADING UNTIL HOME DATA IS FETCHED
  ================================================================
  */
  if (currentHome == null) {
    return (
      <div className="loading-spinner">
        <div className="loading-spinner__outer" />
        <div className="loading-spinner-text">Loading...</div>
      </div>
    );
  }

  /* 
  ================================================================
  RENDER
  ================================================================
  */
  return (
    <div className="introduction">
      {/* Introduction Section */}
      <section className="introduction__intro">
        <p className="introduction__description">
          Before writing an offer for {currentHome.address.address_line_1},
          let's get all relevant financial documents together to make a strong
          offer.
        </p>
        <p className="introduction__description">Steps involved:</p>
      </section>

      {/* Steps Section */}
      <section className="introduction__steps">
        <ol className="introduction__steps-list">
          <li className="introduction__step-item">
            Find a lending company to help you finance your home{" "}
            <span className="introduction__step-note">
              (skip if buying in all cash)
            </span>
          </li>
          <li className="introduction__step-item">
            Obtain a pre-approval letter from the lending company that says how
            much house you can afford{" "}
            <span className="introduction__step-note">
              (skip if buying in all cash)
            </span>
          </li>
          <li className="introduction__step-item">
            Gather proof of funds and personal financial documents
          </li>
        </ol>
      </section>

      {/* Chat Suggestions */}
      <section className="introduction__chats">
        <div
          className="introduction__chat"
          onClick={() => openTool(TOOLS.AI_CHAT)}
        >
          <img className="introduction__chat-icon" src={Chat} alt="chat" />
        </div>

        <div
          className="introduction__chat-suggestion"
          onClick={() =>
            openTool(TOOLS.AI_CHAT, {
              initialPrompt: "What are proof of funds documents?",
            })
          }
        >
          <img
            className="introduction__chat-suggestion-icon"
            src={Chat}
            alt="chat"
          />
          What are proof of funds documents?
        </div>
        
        <div
          className="introduction__chat-suggestion"
          onClick={() =>
            openTool(TOOLS.AI_CHAT, {
              initialPrompt: "What do pre-approval letters look like?",
            })
          }
        >
          <img
            className="introduction__chat-suggestion-icon"
            src={Chat}
            alt="chat"
          />
          What do pre-approval letters look like?
        </div>
      </section>

      {/* Call to Action */}
      <section className="introduction__cta">
        <button className="introduction__cta-button" onClick={handleClick}>
          Continue
        </button>
      </section>
    </div>
  );
};

export default BudgetingIntroduction;
