import { UUIDTypes } from "uuid";
import { UploadType } from "../components/Pages/BuyingFlow/Steps/Budgeting/common";
import {
  deleteWithAuth,
  getWithAuth,
  postWithAuth,
} from "../firebase/authentication";

import { LenderAgentRecommendationResponse } from "../model/Core/BuyingFlow/Budgeting";
import {
  PreApprovalFileResponse,
  ProofOfFundsFileResponse,
} from "../model/Core/Files";

const AGNSY_API_BASE_URL = process.env.REACT_APP_AGNSY_SERVER_ADDRESS;
const HTTP_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";

export enum HTTPStatusCode {
  OK = 200,
  CREATED = 201,
  BAD_REQUEST = 400,
  CONFLICT = 409,
  SERVER_ERROR = 500,
}

/**
 * Uploads files to the appropriate endpoint based on the upload type.
 *
 * @param {File[]} files - The pdf files to be uploaded.
 * @param {UploadType} type - The type of upload, which determines the endpoint.
 * @throws Will throw an error if the upload fails.
 */
export async function UploadFiles(
  files: File[],
  type: UploadType
): Promise<void> {
  if (type === UploadType.PRE_APPROVAL_DATA) {
    for (const file of files) {
      try {
        await uploadPreApproval(file);
      } catch (error) {
        throw error;
      }
    }
  }

  if (type === UploadType.PROOF_OF_FUNDS) {
    for (const file of files) {
      try {
        await uploadProofOfFunds(file);
      } catch (error) {
        throw error;
      }
    }
  }
}

async function uploadPreApproval(file: File): Promise<void> {
  const formData = new FormData();
  formData.append("pdf", file);

  const response = await postWithAuth(
    `${HTTP_PROTOCAL}://${AGNSY_API_BASE_URL}/api/user/documents/pre-approvals`,
    formData
  );

  if (!response.ok) {
    throw new Error("Failed to upload pre-approval data");
  }
}

async function uploadProofOfFunds(file: File): Promise<void> {
  const formData = new FormData();
  formData.append("pdf", file);

  const response = await postWithAuth(
    `${HTTP_PROTOCAL}://${AGNSY_API_BASE_URL}/api/user/documents/proof-of-funds`,
    formData
  );

  if (!response.ok) {
    throw new Error("Failed to upload pre-approval data");
  }
}

export async function getLenderAgentRecommendations(): Promise<
  LenderAgentRecommendationResponse[]
> {
  const options: RequestInit = {
    method: "GET",
    headers: {
      "Conent-Type": "application/json",
    },
  };

  const response = await fetch(
    `${HTTP_PROTOCAL}://${AGNSY_API_BASE_URL}/api/buying-flow/budgeting/lender-agent-recommendations`,
    options
  );

  if (!response.ok) {
    throw new Error("Failed to get lender recommendations");
  }

  const data = await response.json();
  return data as LenderAgentRecommendationResponse[];
}

export async function getUserPreApprovals(): Promise<
  PreApprovalFileResponse[]
> {
  const response = await getWithAuth(
    `${HTTP_PROTOCAL}://${AGNSY_API_BASE_URL}/api/user/documents/pre-approvals`
  );

  if (!response.ok) {
    throw new Error("Failed to retrieve pre approval data");
  }

  const data = await response.json();
  return data as PreApprovalFileResponse[];
}

export async function getUserProofOfFunds(): Promise<
  ProofOfFundsFileResponse[]
> {
  const response = await getWithAuth(
    `${HTTP_PROTOCAL}://${AGNSY_API_BASE_URL}/api/user/documents/proof-of-funds`
  );

  if (!response.ok) {
    throw new Error("Failed to retrieve proof of fund data");
  }

  const data = await response.json();
  return data as ProofOfFundsFileResponse[];
}

export async function deleteUserPreApproval(fileId: UUIDTypes): Promise<void> {
  const response = await deleteWithAuth(
    `${HTTP_PROTOCAL}://${AGNSY_API_BASE_URL}/api/user/documents/pre-approvals/${fileId}`
  );

  if (!response.ok) {
    throw new Error("Failed to delete pre approval file.");
  }
}

export async function deleteUserProofOfFunds(fileId: UUIDTypes): Promise<void> {
  const response = await deleteWithAuth(
    `${HTTP_PROTOCAL}://${AGNSY_API_BASE_URL}/api/user/documents/proof-of-funds/${fileId}`
  );

  if (!response.ok) {
    throw new Error("Failed to delete proof of funds file.");
  }
}
