import { useNavigateWithFlowIdURLParam } from "../BuyingFlow";

interface IncompleteStepProps {
  stepName: string;
  reroute: string;
}

function IncompleteStep({ stepName, reroute }: IncompleteStepProps) {
  const navigate = useNavigateWithFlowIdURLParam();

  return (
    <div
      style={{
        minHeight: "calc(100vh - 200px)",
      }}
    >
      <p>{stepName} is incomplete.</p>
      <button onClick={() => navigate(reroute)}>Complete {stepName}</button>
    </div>
  );
}

export default IncompleteStep;
