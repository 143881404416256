export enum BUYING_FLOW_STEP {
  HOME_SEARCH = "HOME_SEARCH",
  BUDGETING = "BUDGETING",
  OFFER_AND_NEGOTIATION = "OFFER_AND_NEGOTIATION",
  CLOSING = "CLOSING",
  GET_KEYS = "GET_KEYS",
}

export const buyingFlowStepMapping: Record<string, BUYING_FLOW_STEP> = {
  "/homesearch": BUYING_FLOW_STEP.HOME_SEARCH,
  "/budgeting": BUYING_FLOW_STEP.BUDGETING,
  "/offer-negotiation": BUYING_FLOW_STEP.OFFER_AND_NEGOTIATION,
  "/closing": BUYING_FLOW_STEP.CLOSING,
  "/get-keys": BUYING_FLOW_STEP.GET_KEYS,
};

export const buyingFlowStepNumberMapping: Record<BUYING_FLOW_STEP, number> = {
  [BUYING_FLOW_STEP.HOME_SEARCH]: 0,
  [BUYING_FLOW_STEP.BUDGETING]: 1,
  [BUYING_FLOW_STEP.OFFER_AND_NEGOTIATION]: 2,
  [BUYING_FLOW_STEP.CLOSING]: 3,
  [BUYING_FLOW_STEP.GET_KEYS]: 4,
};

export const buyingFlowNumberStepMapping: Record<number, string> = {
  1: "homesearch",
  2: "budgeting",
  3: "offer-negotiation",
  4: "closing",
  5: "get-keys",
};

export const buyingFlowStepTitles: Record<BUYING_FLOW_STEP, string> = {
  [BUYING_FLOW_STEP.HOME_SEARCH]: "Home Search",
  [BUYING_FLOW_STEP.BUDGETING]: "Budgeting",
  [BUYING_FLOW_STEP.OFFER_AND_NEGOTIATION]: "Offer & Negotiation",
  [BUYING_FLOW_STEP.CLOSING]: "Closing",
  [BUYING_FLOW_STEP.GET_KEYS]: "Get Keys",
};
