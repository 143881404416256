import { UUIDTypes } from "uuid";
import { postWithAuth } from "../firebase/authentication";
import { BuyingFlow } from "../model/Core/BuyingFlow";
import { User } from "../model/Core/User";
import { fetchCurrentUserData } from "./user";

const AGNSY_API_BASE_URL = process.env.REACT_APP_AGNSY_SERVER_ADDRESS;
const HTTP_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";

/*
 * Buying Flow Data
 */
export async function getCurrentUserBuyingFlow(
  flowId: UUIDTypes
): Promise<BuyingFlow | null> {
  let user: User | null = await fetchCurrentUserData();
  return user?.buying_flows.find((flow) => flow.id === flowId) || null;
}

/*
 * Budgeting
 */
export async function setBuyingFlowPreApproval(
  flowId: UUIDTypes,
  preApprovalFileId: UUIDTypes
): Promise<void> {
  const formData = new FormData();
  formData.append("buying_flow_id", flowId as string);
  formData.append("pre_approval_file_id", preApprovalFileId as string);

  const response = await postWithAuth(
    `${HTTP_PROTOCAL}://${AGNSY_API_BASE_URL}/api/buying-flow/budgeting/pre-approval`,
    formData
  );

  if (!response.ok) {
    throw new Error("Failed to set pre approval.");
  }
}

export async function toggleBuyingFlowProofOfFunds(
  flowId: UUIDTypes,
  proofOfFundsFileId: UUIDTypes
): Promise<void> {
  const formData = new FormData();
  formData.append("buying_flow_id", flowId as string);
  formData.append("proof_of_funds_file_id", proofOfFundsFileId as string);

  const response = await postWithAuth(
    `${HTTP_PROTOCAL}://${AGNSY_API_BASE_URL}/api/buying-flow/budgeting/proof-of-funds`,
    formData
  );

  if (!response.ok) {
    throw new Error("Failed to toggle proof of funds.");
  }
}
